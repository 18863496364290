import { TableTypes } from '@cango-app/types'
import { GridColType } from '@mui/x-data-grid-premium'

export const getFieldType = (
	type: TableTypes.FieldType,
	returnType: TableTypes.ReturnValueType | undefined,
): GridColType => {
	if (returnType === TableTypes.ReturnValueType.string) {
		return 'string'
	}

	if (returnType === TableTypes.ReturnValueType.number) {
		return 'number'
	}

	if (returnType === TableTypes.ReturnValueType.boolean) {
		return 'boolean'
	}

	switch (type) {
		case TableTypes.FieldType.NUMBER:
		case TableTypes.FieldType.RESOURCES:
		case TableTypes.FieldType.UNIQUE_ID:
			return 'number'
		case TableTypes.FieldType.DATE:
			return 'date'
		case TableTypes.FieldType.CONTACT:
		case TableTypes.FieldType.TABLE_SELECT:
		case TableTypes.FieldType.ROLE:
			return 'singleSelect'
		case TableTypes.FieldType.URL:
		case TableTypes.FieldType.VIDEO:
		case TableTypes.FieldType.CONCATINATION:
			return 'string'
		case TableTypes.FieldType.REFERENCE:
		case TableTypes.FieldType.CALCULATION:
		case TableTypes.FieldType.OPTIONS:
		case TableTypes.FieldType.FILE:
		case TableTypes.FieldType.QUESTIONAIRE_REFERENCE:
			return 'custom'
		default:
			return type
	}
}
