import { GridCellParams } from '@mui/x-data-grid-premium'
import { TableTypes } from '@cango-app/types'
import { IconButton } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

export const getCalculationCell = ({
	cell,
	returnType,
	onCheckIconClick,
}: {
	cell: GridCellParams
	returnType?: TableTypes.ReturnValueType
	onCheckIconClick?: (cell: GridCellParams) => void
}) => {
	switch (returnType) {
		case TableTypes.ReturnValueType.boolean: {
			return (
				<IconButton
					onClick={() => {
						if (onCheckIconClick) {
							onCheckIconClick(cell)
						}
					}}
				>
					{cell.value === 'TRUE' || cell.value === true || cell.value === 'true' ? (
						<CheckIcon />
					) : (
						<CloseIcon />
					)}
				</IconButton>
			)
		}
		case TableTypes.ReturnValueType.string: {
			return cell?.value ? `${cell.value}` : '\u00A0'
		}
		case TableTypes.ReturnValueType.number: {
			return cell?.formattedValue ? String(cell.formattedValue) : '\u00A0'
		}
		default: {
			return cell?.value ? String(cell.value) : '\u00A0'
		}
	}
}
