import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { V3ClientTypes } from '@cango-app/types'

import { RouteId } from 'src/constants/routes'
import { selectors as persistedConfigSelectors } from 'src/store/modules/persisted-config'
import { selectors as projectSelectors } from 'src/store/modules/projects-v3'
import { RootState } from 'src/store/types'
import { getSectionId } from 'src/helpers/chains'

export const useMyTasks = () => {
	const navigate = useNavigate()
	const project = useSelector(projectSelectors.getSelectedProject)
	const groupingType = useSelector((state: RootState) =>
		persistedConfigSelectors.getProjectGroupingConfig(state, project?._id ?? ''),
	)
	const { selectedProjectId, selectedSectionId, selectedTaskId } = useParams<{
		selectedProjectId?: string
		selectedSectionId?: string
		selectedTaskId?: string
	}>()

	const goToProject = (projectId?: string) => {
		navigate(`/${RouteId.MyTasks}/${projectId}`)
	}

	const goToSection = (
		section?: { name: string; chain: V3ClientTypes.Project.Task['chain'] } | string,
	) => {
		if (!section) {
			navigate(`/${RouteId.MyTasks}/${selectedProjectId}`)
			return
		}

		if (typeof section === 'string') {
			navigate(`/${RouteId.MyTasks}/${selectedProjectId}/${section}`)
			return
		}
		const sectionId = getSectionId(section, groupingType)
		navigate(`/${RouteId.MyTasks}/${selectedProjectId}/${sectionId}`)
	}

	const goToTask = (task: Pick<V3ClientTypes.Project.Task, '_id' | 'section' | 'chain'>) => {
		const sectionId = getSectionId({ name: task.section, chain: task.chain }, groupingType)
		navigate(`/${RouteId.MyTasks}/${selectedProjectId}/${sectionId}/${task._id}`)
	}

	return {
		selectedProjectId,
		selectedSectionId,
		selectedTaskId,
		goToProject,
		goToSection,
		goToTask,
	}
}
