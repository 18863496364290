import { createSelector } from 'reselect'
import { createCachedSelector } from 're-reselect'
import { TableTypes } from '@cango-app/types'
import _uniqBy from 'lodash/uniqBy'

import { LinkedTable } from 'src/modules/tables/types'
import { resolveAnyRowCalculations } from 'src/modules/tables/utils'
import { getLayout } from 'src/modules/tables/questionaire-logic/get-layout'
import { getFieldType } from 'src/modules/tables/mui-formatter'
import { selectors as contactSelectors } from 'src/store/modules/contacts/selectors'
import { selectors as roleSelectors } from 'src/store/modules/roles/selectors'
import { selectors as imageSelectors } from 'src/store/modules/images/selectors'
import { RootState } from 'src/store/types'

import { ResolvedRowData, TableState } from './types'

export const selectFields = createSelector(
	(state: TableState) => state.fields,
	(state: TableState, rootState: RootState) => contactSelectors.getContactsForSelect(rootState),
	(state: TableState, rootState: RootState) => roleSelectors.getRolesForSelect(rootState),
	(fields, contactList, roleList) =>
		fields.map((_field) => {
			if (_field.type === TableTypes.FieldType.ROLE) {
				_field.valueOptions = roleList
			}
			if (_field.type === TableTypes.FieldType.CONTACT) {
				_field.valueOptions = contactList
			}
			return _field
		}),
)

export const selectMappedColumns = createSelector(selectFields, (fields) => {
	return new Map(fields.map((_field) => [_field._id, _field]))
})

export const selectRecords = (state: TableState) => state.records

export const selectLinkedTable: (state: TableState, tableId?: string) => LinkedTable | undefined =
	createSelector(
		(state: TableState) => state.tableList,
		(state: TableState, tableId?: string) => tableId,
		(tableList, tableId) => {
			return tableList.find((_table) => _table._id === tableId)?.linkedTable
		},
	)

export const selectConfig = (data: TableState) => data.config
const selectRowsBeforeChange = (data: TableState) => Object.keys(data.recordsBeforeChange)
const selectQuestionColumnId = (data: TableState) => data.config?.principal_field ?? ''
const selectOptionsColumnId = (data: TableState) =>
	data.fields.find((field) => field.type === TableTypes.FieldType.OPTIONS)?._id ?? ''

const selectResolveRow = createCachedSelector(
	selectFields,
	selectConfig,
	(state, rootState) => imageSelectors.getMappedImages(rootState),
	(state, rootState, row) => row,
	(fields, config, images, row) =>
		resolveAnyRowCalculations({
			row,
			fields,
			referenceColumns: config?.referenceColumnNames ?? {},
			questionaireAnswers: config?.questionaire_answers ?? [],
			images,
			vLookupTables: config?.vLookupTables ?? {},
		}),
)(
	(state, rootState, row) => row._id, // Cache key by row ID
)

const selectUniqueRecords = createSelector(selectRecords, (records) => _uniqBy(records, '_id'))

const selectSortedRecordIds = createSelector(
	selectConfig,
	selectUniqueRecords,
	selectQuestionColumnId,
	selectOptionsColumnId,
	(config, uniqueRecords, questionColumnId, optionsColumnId) => {
		if (!config) {
			return uniqueRecords.map((row) => row._id)
		}

		if (config.type === TableTypes.TableType.Questionaire) {
			const { nodes } = getLayout({ rows: uniqueRecords, optionsColumnId, questionColumnId })
			const nodeIds = nodes.map((node) => node.id)
			return uniqueRecords
				.slice()
				.sort((a, b) => nodeIds.indexOf(a._id) - nodeIds.indexOf(b._id))
				.map((row) => row._id)
		} else if (config.row_order) {
			return uniqueRecords
				.slice()
				.sort((a, b) => config.row_order.indexOf(a._id) - config.row_order.indexOf(b._id))
				.map((row) => row._id)
		}

		return uniqueRecords.map((row) => row._id)
	},
)

// Selector for sorted records
const selectSortedRecords = createSelector(
	selectUniqueRecords,
	selectSortedRecordIds,
	(uniqueRecords, sortedIds) => {
		const recordMap = uniqueRecords.reduce((acc: Record<string, TableTypes.Record>, row) => {
			acc[row._id] = row
			return acc
		}, {})
		return sortedIds.map((id) => recordMap[id]).filter((row) => row)
	},
)

// Selector for resolved records based on sorted records
const selectResolvedRecords = createSelector(
	selectSortedRecords,
	(state: TableState) => state,
	(state: TableState, rootState: RootState) => rootState,
	(sortedRecords, tableState, rootState) =>
		sortedRecords.map((row) => selectResolveRow(tableState, rootState, row)),
)

// Final selector for resolved rows
export const selectResolvedRows = createSelector(
	selectResolvedRecords,
	(resolvedRecords) => resolvedRecords,
)

export const selectMappedRowData: (
	state: TableState,
	rootState: RootState,
) => Map<string, ResolvedRowData & { _id: string }> = createSelector(
	selectResolvedRows,
	(records) => {
		return new Map(records.map((_row) => [_row._id, { ..._row, _id: _row._id }]))
	},
)

export const selectMappedRecords = createSelector(
	selectRecords,
	selectMappedRowData,
	(records, rowData) => {
		return new Map(
			records.map((_row) => [
				_row._id,
				{
					..._row,
					data: rowData.get(_row._id) ?? _row.data,
					_id: _row._id,
				},
			]),
		)
	},
)

export const selectColumnList = createSelector(selectFields, (fields) => {
	return fields.map(({ _id, name }) => ({
		_id,
		label: name,
	}))
})

export const selectColumnFilterList = createSelector(selectFields, (fields) => {
	return fields.map(({ _id, type, returnType }) => ({
		_id,
		type: getFieldType(type, returnType),
	}))
})

export const selectCachedRowIds = createSelector(
	selectRowsBeforeChange,
	(state: TableState) => state.newRecords,
	(rowsBeforeChange, newRows) => {
		return new Set<string>([...rowsBeforeChange, ...newRows])
	},
)

const selectBlueprintTableRecords: (state: TableState) => TableTypes.Record[] = createSelector(
	(state: TableState) => state.blueprintTable?.records,
	(records) => records ?? [],
)

export const selectedMappedBlueprintRecords = createSelector(
	selectBlueprintTableRecords,
	(records) => {
		return new Map(records.map((_record) => [_record._id, _record]))
	},
)

export const selectValueOptions = createSelector(selectFields, (fields) => {
	return fields.reduce((acc: Record<string, Map<string, string>>, _field) => {
		if (!_field.valueOptions) {
			return acc
		}
		acc[_field._id] = new Map(_field.valueOptions.map(({ _id, label }) => [_id, label]))
		return acc
	}, {})
})
