import { V3BlueprintTypes, V3ClientTypes, V3ProjectTypes } from '@cango-app/types'
import { createSelector } from '@reduxjs/toolkit'
import _uniqBy from 'lodash/uniqBy'

import { isStepReady } from 'src/providers/task-provider/utils'
import { ListedStep } from 'src/modules/chains/types'
import { convertAndPopulateDescendants } from 'src/modules/my-tasks-v3/components/complete-task-cta/utils'
import { RootState } from 'src/store/types'
import { selectors as projectSelectors } from 'src/store/modules/projects-v3'

export const filterDescendantsBySelection = (
	task: V3ClientTypes.Project.Task,
	descendants: V3ClientTypes.Project.ProjectDescendant[],
	chainTasks: V3ClientTypes.Project.Task[],
	completed_options: {
		_id: string
		label: string
	}[],
) => {
	const filteredDescendants =
		descendants.reduce((_descendants: V3ClientTypes.Project.ProjectDescendant[], _desc) => {
			if (!_desc.step?._id && !_desc.chain_complete_point) {
				return _descendants
			}

			const optionConditionFrom =
				chainTasks.find(({ _id }) => _desc.option_condition?.from === _id) ?? task
			const completedOptions =
				optionConditionFrom._id === task._id
					? completed_options
					: optionConditionFrom.lifecycle.completed_options

			if (_desc.option_condition?.values.length && !completedOptions.length) {
				return _descendants
			}

			if (
				_desc.option_condition?.operator === V3BlueprintTypes.ChildConditionOperator.Is &&
				!completedOptions.some(({ _id }) => _desc.option_condition?.values.includes(_id))
			) {
				return _descendants
			}

			return [..._descendants, _desc]
		}, []) ?? []

	return _uniqBy(filteredDescendants, '_id')
}

export const getAvailableDescendants = ({
	activeTask,
	chainTasks,
	completed_options,
	projectTasks,
	projectSteps,
	project,
	taskParents,
}: {
	activeTask: V3ClientTypes.Project.Task | undefined
	chainTasks: V3ClientTypes.Project.Task[]
	completed_options: V3ProjectTypes.CompletedOption[]
	projectSteps: ListedStep[]
	projectTasks: V3ClientTypes.Project.Task[]
	project: V3ClientTypes.Project.Project
	taskParents: V3ClientTypes.Project.Task[]
}): V3ClientTypes.Project.ProjectDescendant[] => {
	if (!activeTask) {
		return []
	}

	let stepDescendants: V3ClientTypes.Project.ProjectDescendant[] = filterDescendantsBySelection(
		activeTask,
		activeTask.step?.descendants ?? [],
		chainTasks,
		completed_options,
	)

	const completingTaskStep = projectSteps.find(({ _id }) => _id === activeTask.step?._id)
	const isParentPartOfReferencedChain = completingTaskStep?.chain_id !== project.chain
	const inCompleteTasksInSameChain = projectTasks.filter((_task) => {
		if (_task._id === activeTask._id) return false
		if (_task.lifecycle.complete || _task.reference_group !== activeTask.reference_group)
			return false
		const taskStep = projectSteps.find(({ _id }) => _id === _task.step?._id)
		if (!taskStep) return false
		return taskStep.chain_id === completingTaskStep?.chain_id
	})
	const referencedStepInBlueprint = projectSteps.find(
		({ chain_reference, _id }) =>
			chain_reference === completingTaskStep?.chain_id &&
			taskParents.some(({ step }) => step?.chain_id !== completingTaskStep?.chain_id),
	)
	const shouldCreateChildrenOfReferencedTask =
		isParentPartOfReferencedChain &&
		((!inCompleteTasksInSameChain.length && !stepDescendants.length) ||
			stepDescendants.some(({ chain_complete_point }) => chain_complete_point))

	if (shouldCreateChildrenOfReferencedTask) {
		if (!referencedStepInBlueprint) {
			return []
		}
		if (!inCompleteTasksInSameChain.length && !stepDescendants.length) {
			stepDescendants = convertAndPopulateDescendants(
				referencedStepInBlueprint.descendants,
				new Map(projectSteps.map((_step) => [_step._id, _step])),
			)
		} else if (stepDescendants.some(({ chain_complete_point }) => chain_complete_point)) {
			stepDescendants.forEach(({ chain_complete_point, chain_endings }) => {
				if (!chain_complete_point) return
				const newDescendants = referencedStepInBlueprint.descendants.map((_desc) => ({
					..._desc,
					chain_endings: [...(_desc.chain_endings ?? []), ...(chain_endings ?? [])],
				}))
				const populatedDescendants = convertAndPopulateDescendants(
					newDescendants,
					new Map(projectSteps.map((_step) => [_step._id, _step])),
				)
				stepDescendants.push(...populatedDescendants)
			})
			const filteredDescendants = stepDescendants.filter(
				({ chain_complete_point }) => !chain_complete_point,
			)
			stepDescendants = _uniqBy([...stepDescendants, ...filteredDescendants], '_id')
		}
	}

	const sectionSteps = new Map<string, string>()

	stepDescendants.forEach((_desc) => {
		if (_desc.step?.isSection) {
			_desc.step.descendants.forEach((_sectionDesc) => {
				if (!_sectionDesc.step?._id) {
					return
				}
				sectionSteps.set(String(_sectionDesc.step._id), String(_desc.step!._id))
			})
		}
	})

	if (!stepDescendants.length) {
		return []
	}

	return stepDescendants.reduce(
		(_descendants: V3ClientTypes.Project.ProjectDescendant[], _desc) => {
			const _descStep = _desc.step!
			if (!_desc.step) {
				return _descendants
			}

			if (_desc.multi_use_config || shouldCreateChildrenOfReferencedTask) {
				_descendants.push(_desc)
				return _descendants
			}

			if (
				_descStep.isSection &&
				_descStep.descendants.every((_sectionDesc) => {
					if (!_sectionDesc.step?._id) {
						return false
					}

					return isStepReady({
						step: _sectionDesc.step,
						sectionSteps,
						blueprintSteps: projectSteps,
						projectTasks,
						activeTask,
						completed_options,
						chainTasks,
					})
				})
			) {
				_descendants.push(_desc)
			} else if (
				!_desc.step.isSection &&
				isStepReady({
					step: _descStep,
					sectionSteps,
					blueprintSteps: projectSteps,
					projectTasks,
					activeTask,
					completed_options,
					chainTasks,
				})
			) {
				_descendants.push(_desc)
			}

			return _descendants
		},
		[],
	)
}

export const selectAvailableDescendants: (
	state: RootState,
	completed_options: {
		_id: string
		label: string
	}[],
	task: V3ClientTypes.Project.Task | undefined,
) => V3ClientTypes.Project.ProjectDescendant[] = createSelector(
	projectSelectors.getProjectSteps,
	projectSelectors.getSelectedProject,
	(
		state: RootState,
		completed_options: {
			_id: string
			label: string
		}[],
		task: V3ClientTypes.Project.Task | undefined,
	) => projectSelectors.getProjectTaskAscendants(state, task),
	(
		state: RootState,
		completed_options: {
			_id: string
			label: string
		}[],
		task: V3ClientTypes.Project.Task | undefined,
	) => projectSelectors.getProjectTasksInChain(state, task),
	(state: RootState) => projectSelectors.getProjectTasks(state),
	(
		state: RootState,
		completed_options: {
			_id: string
			label: string
		}[],
		task: V3ClientTypes.Project.Task | undefined,
	) => ({ completed_options, task }),
	(
		projectSteps,
		project,
		taskParents,
		chainTasks,
		projectTasks,
		{ completed_options, task: activeTask },
	) => {
		if (!project || !activeTask) {
			return []
		}
		return getAvailableDescendants({
			activeTask,
			chainTasks,
			completed_options,
			projectTasks,
			projectSteps,
			project,
			taskParents,
		})
	},
)
