import { V3BlueprintTypes, V3ClientTypes } from '@cango-app/types'
import _get from 'lodash/get'
import _uniqBy from 'lodash/uniqBy'

import { ProjectSection } from 'src/store/modules/projects-v3/selectors'

import { ProjectGroupingType } from '../store/modules/persisted-config'

type TaskWithSectionId = V3ClientTypes.Project.Task & { sectionId: string }

type TaskWithScope = V3ClientTypes.Project.Task & { scope: string[] }

const nextEligibleTask = ({
	taskId,
	direction,
	checkOppositeDirection,
	sections,
	goToTask,
}: {
	taskId: string
	direction: 1 | -1
	checkOppositeDirection?: boolean
	sections: ProjectSection[]
	goToTask?: string
}): V3ClientTypes.Project.Task | null => {
	let currentSectionId: string | undefined = undefined

	const taskList = sections.reduce((_acc: TaskWithSectionId[], _section) => {
		return [
			..._acc,
			..._section.tasks.map((_task) => {
				if (_task._id === taskId) {
					currentSectionId = _section._id
				}
				return {
					..._task,
					sectionId: _section._id,
				}
			}),
		]
	}, [])
	const currentIndex = taskList.findIndex(({ _id }) => _id === taskId)
	if (goToTask) {
		const goToTaskIndex = taskList.findIndex(({ step }) => step?._id === goToTask)
		if (goToTaskIndex !== -1) {
			return taskList[goToTaskIndex]
		}
	}

	let index = currentIndex

	if (index < 0) return null

	// Define a helper function to check if a task is eligible
	const isEligible = (task: TaskWithSectionId | undefined) => {
		if (!task || task.sectionId !== currentSectionId) return false
		return !(task.isMultiUse || task.lifecycle.complete)
	}

	// Check in the initial direction
	while (index >= 0 && index < taskList.length) {
		index += direction
		const task = _get(taskList, index)
		if (isEligible(task)) {
			return task
		}
	}

	// If no eligible task found in the initial direction and checkOppositeDirection is true
	if (checkOppositeDirection) {
		index = currentIndex
		while (index >= 0 && index < taskList.length) {
			index -= direction // Opposite direction
			const task = _get(taskList, index)
			if (isEligible(task)) {
				return task
			}
		}
	}

	// If still no eligible task found, return null
	return null
}

export const getSectionId = (
	section: {
		name: string
		chain: V3ClientTypes.Project.Task['chain']
	},
	groupBy: ProjectGroupingType,
) => {
	if (groupBy === ProjectGroupingType.Tags && section.chain?._id) {
		return `${section.chain._id}+${section.name}`
	}

	return section.name
}

const constructProjectSections = (
	projectTasks: V3ClientTypes.Project.Task[],
	groupBy?: ProjectGroupingType,
) => {
	const chains = new Map<string, V3ClientTypes.Project.Task['chain']>()

	let sections: {
		sectionName: string
		projectId: string
		chainId?: string
		chain: V3ClientTypes.Project.Task['chain']
	}[] = []
	if (groupBy === ProjectGroupingType.Sections) {
		sections = projectTasks.reduce(
			(
				_acc: {
					sectionName: string
					projectId: string
					chain: V3ClientTypes.Project.Task['chain']
				}[],
				_task,
			) => {
				if (_task.section === V3BlueprintTypes.PROJECT_WIDE_SECTION_NAME) return _acc
				if (!_acc.some((_section) => _section.sectionName === _task.section)) {
					_acc.push({
						sectionName: _task.section,
						projectId: _task.project_id,
						chain: undefined,
					})
				}
				return _acc
			},
			[],
		)
	} else if (groupBy === ProjectGroupingType.Tags) {
		const chainSections = projectTasks.reduce(
			(_acc: { chainId?: string; sectionName: string; projectId: string }[], _task) => {
				if (_task.section === V3BlueprintTypes.PROJECT_WIDE_SECTION_NAME) return _acc

				if (_task.chain?._id && !chains.has(_task.chain._id)) {
					chains.set(_task.chain._id, _task.chain)
				}

				const taskChainId = _task.chain?._id

				if (!_acc.some((_section) => _section.sectionName === _task.section)) {
					_acc.push({
						chainId: taskChainId,
						sectionName: _task.section,
						projectId: _task.project_id,
					})
					return _acc
				}

				const correspondingChainSections = _acc.filter(({ chainId }) => chainId === taskChainId)

				if (!correspondingChainSections.length) {
					_acc.push({
						chainId: taskChainId,
						sectionName: _task.section,
						projectId: _task.project_id,
					})
					return _acc
				}

				const matchingSection = correspondingChainSections.find(
					({ sectionName }) => sectionName === _task.section,
				)

				if (!matchingSection) {
					_acc.push({
						chainId: taskChainId,
						sectionName: _task.section,
						projectId: _task.project_id,
					})
					return _acc
				}
				return _acc
			},
			[],
		)

		sections = chainSections.map((_section) => {
			return {
				..._section,
				chain: chains.get(_section.chainId ?? ''),
			}
		})
	}

	return sections
}

export const getChainList = (chain: V3ClientTypes.Project.Task['chain']) => {
	const parentThreads = chain?.parent_chains ?? []

	if (!chain?._id) {
		return _uniqBy(parentThreads, '_id')
	}

	return _uniqBy([...parentThreads, chain], '_id')
}

// tasks are ordered in time sequence (oldest to newest)
// if a task has a chain of (e.g.) 3 chains, and there is an earlier task that has 2 chains that match 2 of these chains, it should appear directly below the matching task. The chains are like a scope
// if a new task gets created with no chains after the chain with tasks, it should appear at the bottom (in time order), not at the top as it is at the moment
const orderTasksByScope = (tasks: V3ClientTypes.Project.Task[]) => {
	const tasksWithScope: TaskWithScope[] = tasks.map((_task) => ({
		..._task,
		scope: getChainList(_task.chain).map(({ _id }) => _id),
	}))

	// Step 1: Sort by created_at first
	tasksWithScope.sort((a, b) => a.created_at - b.created_at)

	// Step 2: Parent-Child Enforcement Without Breaking Chronological Order
	function buildHierarchy(tasks: TaskWithScope[]) {
		const taskMap = new Map<string, TaskWithScope>()
		const childMap = new Map<string, TaskWithScope[]>()

		tasks.forEach((task) => {
			taskMap.set(task._id, task)
			if (task.parent?._id) {
				if (!childMap.has(task.parent._id)) {
					childMap.set(task.parent._id, [])
				}
				childMap.get(task.parent._id)!.push(task)
			}
		})

		const result: TaskWithScope[] = []
		const visited = new Set<string>()

		function insertTask(task: TaskWithScope) {
			if (visited.has(task._id)) return
			visited.add(task._id)

			result.push(task)

			if (childMap.has(task._id)) {
				// Ensure children are placed after their parent but still maintain creation order
				const sortedChildren = childMap.get(task._id)!.sort((a, b) => a.created_at - b.created_at)
				sortedChildren.forEach(insertTask)
			}
		}

		// Ensure the first sorting step is always by creation date, then insert parents before children
		tasks.forEach(insertTask)

		return result
	}

	return buildHierarchy(tasksWithScope)
}

export const constructProjectSectionsWithTasks = ({
	tasks,
	groupBy = ProjectGroupingType.Tags,
	returnAll,
	userId,
}: {
	tasks: V3ClientTypes.Project.Task[]
	groupBy?: ProjectGroupingType
	returnAll?: boolean
	userId: string
}) => {
	const orderedTasks = orderTasksByScope(tasks)
	const sections = constructProjectSections(orderedTasks, groupBy)

	const allTasks = Array.from(tasks.values())

	if (groupBy === ProjectGroupingType.Sections) {
		return sections.reduce((_acc: ProjectSection[], _section) => {
			const sectionTasks: V3ClientTypes.Project.Task[] = allTasks.filter((_task) => {
				return !(
					_task.section !== _section.sectionName ||
					(!returnAll && !_task.assignees?.some(({ user }) => user === userId))
				)
			})

			if (
				!returnAll &&
				(!sectionTasks.length || sectionTasks.every((_task) => _task.lifecycle.complete))
			) {
				return _acc
			}
			return [
				..._acc,
				{
					_id: getSectionId({ name: _section.sectionName, chain: _section.chain }, groupBy),
					name: _section.sectionName,
					tasks: sectionTasks,
					projectId: _section.projectId,
					chain: undefined,
					isBlocked: sectionTasks.some(({ isFlagged }) => isFlagged),
				},
			]
		}, [])
	}

	return sections.reduce((_acc: ProjectSection[], _section) => {
		const sectionTasks: V3ClientTypes.Project.Task[] = allTasks.filter((_task) => {
			if (
				_task.section !== _section.sectionName ||
				(!returnAll && !_task.assignees?.some(({ user }) => user === userId))
			)
				return false
			return _section.chain?._id === _task.chain?._id
		})

		if (
			!returnAll &&
			(!sectionTasks.length || sectionTasks.every((_task) => _task.lifecycle.complete))
		) {
			return _acc
		}
		return [
			..._acc,
			{
				_id: getSectionId({ name: _section.sectionName, chain: _section.chain }, groupBy),
				name: _section.sectionName,
				tasks: sectionTasks,
				chain: _section.chain,
				isBlocked: sectionTasks.some(({ isFlagged }) => isFlagged),
				projectId: _section.projectId,
			},
		]
	}, [])
}

export const getNextEligibleTask = (
	currentTaskId: string,
	tasks: V3ClientTypes.Project.Task[],
	groupBy: ProjectGroupingType,
	userId: string,
	goToTask: string | undefined,
) => {
	const sections = constructProjectSectionsWithTasks({ tasks, returnAll: false, userId, groupBy })
	return nextEligibleTask({
		taskId: currentTaskId,
		direction: 1,
		checkOppositeDirection: true,
		sections,
		goToTask,
	})
}
