import { TableTypes, V3ClientTypes, V3ProjectTypes } from '@cango-app/types'
import _orderBy from 'lodash/orderBy'
import { GridFilterItem } from '@mui/x-data-grid-pro'
import {
	getGridNumericOperators,
	getGridSingleSelectOperators,
	getGridStringOperators,
	getGridBooleanOperators,
	GridColType,
	GridFilterModel,
	GridRowModel,
} from '@mui/x-data-grid-premium'
import dayjs from 'dayjs'
import _isArray from 'lodash/isArray'
import _toLower from 'lodash/toLower'
import _has from 'lodash/has'
import { TablesSdk } from '@cango-app/sdk'
import _union from 'lodash/union'
import _keys from 'lodash/keys'
import _reduce from 'lodash/reduce'
import _isEqual from 'lodash/isEqual'
import _replace from 'lodash/replace'
import _trim from 'lodash/trim'
import { v4 } from 'uuid'
import { isNumber } from 'lodash'

import { PopulatedAnswer } from 'src/modules/tables/types'
import {
	MappedValueOptions,
	ResolveAnyRowCalculations,
	ResolvedRowData,
} from 'src/providers/table-provider/types'

import { SingleSelectForm } from './column-settings/types'

export const SINGLE_SELECT_FIELDS: TableTypes.FieldType[] = [
	TableTypes.FieldType.TABLE_SELECT,
	TableTypes.FieldType.SINGLE_SELECT,
	TableTypes.FieldType.REFERENCE,
	TableTypes.FieldType.CONTACT,
	TableTypes.FieldType.ROLE,
	TableTypes.FieldType.QUESTIONAIRE_REFERENCE,
]

const buildDependencyGraph = (fields: TableTypes.Field[]) => {
	const graph = new Map<string, Set<string>>()
	fields.forEach((field) => {
		if (field.type === 'calculation') {
			field.calculation.forEach((part) => {
				if (part.type === TableTypes.FormulaSliceType.FIELD) {
					if (!graph.has(field._id)) {
						graph.set(field._id, new Set())
					}
					graph.get(field._id)?.add(part.value)
				}
			})
		}
	})
	return graph
}

const topologicalSort = (fields: TableTypes.Field[], dependencyGraph: Map<string, Set<string>>) => {
	const visitedFields = new Map()
	const sortedFields: TableTypes.Field[] = []

	const visitField = (fieldId: string) => {
		if (visitedFields.get(fieldId)) {
			return
		}
		visitedFields.set(fieldId, true)

		const dependencies = dependencyGraph.get(fieldId)
		if (dependencies) {
			dependencies.forEach((dependentFieldId) => {
				visitField(dependentFieldId)
			})
		}

		const field = fields.find((field) => field._id === fieldId)
		if (field) {
			sortedFields.push(field)
		}
	}

	dependencyGraph.forEach((_, fieldId: string) => {
		visitField(fieldId)
	})

	fields.forEach((field) => {
		if (!visitedFields.get(field._id)) {
			sortedFields.push(field)
		}
	})

	return sortedFields
}

const getRowAnswer = ({
	row,
	field,
	referenceColumns,
	questionaireAnswers,
	images,
}: {
	row: Omit<TableTypes.Record, 'calculations'>
	field: TableTypes.Field
	referenceColumns: TableTypes.ReferenceColumnNames
	questionaireAnswers: TableTypes.QuestionaireAnswer[]
	images: Map<string, string>
}): PopulatedAnswer[] => {
	const questionAnswer = TablesSdk.getQuestionResponse({
		questionaireAnswers,
		questionColumnId: field._id,
		rowData: row.data,
	})

	if (!questionAnswer?.length) {
		return []
	}

	const questionOptions = TablesSdk.getQuestionOptions({
		referenceColumns,
		fieldId: field._id,
		row,
	})

	return questionAnswer.reduce(
		(_answers: { _id: string; label: string; imageUrl?: string }[], _answer) => {
			if (_answer.text) {
				_answers.push({
					_id: _answer._id,
					label: _answer.text,
				})
				return _answers
			}

			const answerLabel = questionOptions.find(({ _id }) => _id === _answer.option_id)
			if (answerLabel) {
				_answers.push({
					_id: _answer._id,
					label: answerLabel?.label,
					imageUrl: images.get(answerLabel.image?.path ?? ''),
				})
			}
			return _answers
		},
		[],
	)
}

export const resolveAnyRowCalculations = ({
	row,
	fields,
	referenceColumns,
	questionaireAnswers,
	images,
	vLookupTables,
}: ResolveAnyRowCalculations): ResolvedRowData => {
	const dependencyGraph = buildDependencyGraph(fields)
	const sortedFields = topologicalSort(fields, dependencyGraph)
	const rowData: {
		[key: string]: TableTypes.RecordDataItem | PopulatedAnswer[]
	} = {}
	sortedFields.forEach((field) => {
		const rowKey = field._id

		if (field.type === TableTypes.FieldType.CALCULATION) {
			try {
				let value: string | number | boolean | null
				if (_has(row.overrides, field._id)) {
					value = row.overrides[field._id]
				} else {
					value = TablesSdk.resolveCalculation({
						calculation: row.calculations?.[field._id] ?? field.calculation ?? [],
						record: {
							...row,
							data: { ...row.data, ...(rowData as Record<string, TableTypes.RecordDataItem>) },
						},
						mappedFields: new Map(fields.map((_field) => [_field._id, _field])),
						questionaireResponses: questionaireAnswers,
						column: field,
						referenceFields: referenceColumns,
						vLookupTables,
					})
				}
				if (field.returnType === TableTypes.ReturnValueType.boolean) {
					rowData[rowKey] = value === 'TRUE' || value === true || value === 'true'
					return
				}
				rowData[rowKey] = value

				return
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(error)
				rowData[rowKey] = null
				return
			}
		}

		rowData[rowKey] = row.data[rowKey]

		if (field.type === TableTypes.FieldType.QUESTIONAIRE_REFERENCE) {
			rowData[`${field._id}_answer`] = getRowAnswer({
				row,
				field,
				referenceColumns,
				questionaireAnswers,
				images,
			})
		}
	})
	return { _id: row._id, ...rowData }
}

export const resolveResources = (resources: TableTypes.ResourceRecord) => {
	if (!resources?.data) return 0
	return resources.data.reduce((acc, record) => {
		const usage = record['usage'] || 1
		return acc + record['rate'] * record['quantity'] * usage
	}, 0)
}

export const getFilteredChains = (
	descendant: V3ClientTypes.Project.ProjectDescendant,
	chain?: V3ProjectTypes.ProjectChain,
) => {
	const isExistingChainGone = descendant.chain_endings?.includes(
		chain?.original_descendant_id ?? '',
	)

	return (
		chain?.parent_chains.reduce(
			(_chains: V3ProjectTypes.ProjectChain[], _parentChain) => {
				if (descendant.chain_endings?.includes(_parentChain.original_descendant_id)) {
					return _chains
				}
				return [..._chains, _parentChain]
			},
			isExistingChainGone ? [] : chain ? [chain] : [],
		) ?? []
	)
}

export const getFilters = ({
	chains,
	viewFilters,
	mappedColumns,
	items,
}: {
	chains: Pick<V3ProjectTypes.ProjectChain, 'database_chain_logic'>[]
	viewFilters?: GridFilterItem[]
	mappedColumns: Map<string, TableTypes.Field>
	items?: GridFilterItem[]
}) => {
	const allFilters = items ?? [
		...(chains.reduce((_parentFilters: GridFilterItem[], _parentChain) => {
			_parentFilters.push(...(_parentChain.database_chain_logic?.filters.items ?? []))
			return _parentFilters
		}, []) ?? []),
		...(viewFilters ?? []),
	]

	return allFilters.reduce((_accFilters: GridFilterItem[], _filter) => {
		const column = mappedColumns.get(_filter.field)
		const columnType = column?.type
		if (!columnType) return _accFilters
		if (columnType === 'reference') {
			return _accFilters
		}

		if (!_filter.id) {
			_filter.id = v4()
		}

		const stringOperators = getGridStringOperators().map((_operator) => _operator.value)
		const singleSelectOperators = getGridSingleSelectOperators().map((_operator) => _operator.value)
		const numberOperators = getGridNumericOperators().map((_operator) => _operator.value)
		const booleanOperators = getGridBooleanOperators().map((_operator) => _operator.value)

		if (columnType === 'string' && !stringOperators.includes(_filter.operator)) {
			return [
				..._accFilters,
				{
					..._filter,
					operator: 'equals',
				},
			]
		}

		if (
			columnType === 'calculation' &&
			column?.returnType === TableTypes.ReturnValueType.number &&
			!numberOperators.includes(_filter.operator)
		) {
			return [
				..._accFilters,
				{
					..._filter,
					operator: '=',
				},
			]
		}

		if (
			columnType === 'calculation' &&
			column?.returnType === TableTypes.ReturnValueType.string &&
			!stringOperators.includes(_filter.operator)
		) {
			return [
				..._accFilters,
				{
					..._filter,
					operator: 'equals',
				},
			]
		}

		if (
			columnType === 'calculation' &&
			column?.returnType === TableTypes.ReturnValueType.boolean &&
			!booleanOperators.includes(_filter.operator)
		) {
			return [
				..._accFilters,
				{
					..._filter,
					operator: 'is',
				},
			]
		}

		if (columnType === 'singleSelect' && !singleSelectOperators.includes(_filter.operator)) {
			return [
				..._accFilters,
				{
					..._filter,
					operator: 'is',
				},
			]
		}
		return [..._accFilters, _filter]
	}, [])
}

export const getCleanedUpSingleSelectData = (
	newData: SingleSelectForm,
	oldData: {
		options: TableTypes.Field['valueOptions']
		valueOptionFilters: TableTypes.Field['valueOptionFilters']
		single_select_lookup:
			| {
					tableId: string
					fieldId: string
			  }
			| undefined
	},
): {
	valueOptions: TableTypes.Field['valueOptions']
	single_select_lookup: TableTypes.Field['single_select_lookup']
	valueOptionFilters: TableTypes.Field['valueOptionFilters']
} => {
	// cases
	// old lookup new lookup
	// old lookup new custom -> reset single select filters
	// old custom new lookup
	// old custom new custom -> reset single select filters
	const orderedOptions = _orderBy(newData.options, 'label').filter(({ label }) => label)
	if (oldData.single_select_lookup?.tableId && newData.single_select_lookup?.tableId) {
		return {
			...newData,
			valueOptions: [],
		}
	}
	if (oldData.single_select_lookup?.tableId && !newData.single_select_lookup?.tableId) {
		return {
			valueOptions: orderedOptions,
			single_select_lookup: { tableId: '', fieldId: '' },
			valueOptionFilters: newData.valueOptionFilters.map(({ _id, label }) => ({
				_id,
				label,
				filters: { items: [] },
				options: [],
			})),
		}
	}
	if (!oldData.single_select_lookup?.tableId && newData.single_select_lookup?.tableId) {
		return {
			valueOptionFilters: newData.valueOptionFilters.map(({ _id, label }) => ({
				_id,
				label,
				filters: { items: [] },
				options: [],
			})),
			single_select_lookup: newData.single_select_lookup,
			valueOptions: [],
		}
	}
	if (!oldData.single_select_lookup?.tableId && !newData.single_select_lookup?.tableId) {
		return {
			...newData,
			valueOptions: orderedOptions,
			single_select_lookup: { tableId: '', fieldId: '' },
		}
	}
	return {
		...newData,
		valueOptions: newData.options,
	}
}

export const columnValueGetter = (
	value: any,
	field: TableTypes.Field,
	valueOptions: Record<string, Map<string, string>>,
) => {
	if (field.type === TableTypes.FieldType.BOOLEAN) {
		if (value === 'FALSE' || value === 'false') {
			return false
		}
		return value === 'TRUE' || value === true || value === 'true'
	}

	if (
		field.type === TableTypes.FieldType.CALCULATION &&
		field.returnType === TableTypes.ReturnValueType.boolean
	) {
		return value === 'TRUE' || value === true || value === 'true'
	}

	if (field.type === TableTypes.FieldType.RESOURCES) {
		return resolveResources(value)
	}

	if (
		field.type === TableTypes.FieldType.NUMBER &&
		field.format === TableTypes.FormatType.percentage
	) {
		return typeof value === 'number' ? value * 100 : value
	}

	if (SINGLE_SELECT_FIELDS.includes(field.type)) {
		const _selectedOption = valueOptions[field._id]?.get(value)
		if (!_selectedOption) {
			return value
		}
		return _selectedOption
	}

	return value
}

export const columnValueFormatter = (
	value: any,
	field: TableTypes.Field,
	mappedValueOptions: MappedValueOptions,
) => {
	let fixedValue = value
	if (isNumber(field.numberOfDecimals) && isNumber(value)) {
		fixedValue = value.toFixed(field.numberOfDecimals)
	}
	if (field.type === TableTypes.FieldType.DATE) {
		return dayjs(value).format('MMM DD, YYYY')
	}

	if (field.format === TableTypes.FormatType.currency) {
		return value || typeof value === 'number' ? '$' + Number(value).toFixed(2) : null
	}

	if (field.format === TableTypes.FormatType.percentage) {
		return value || typeof value === 'number' ? `${fixedValue} %` : null
	}

	if (
		field.format === TableTypes.FormatType.unformatted &&
		[TableTypes.FieldType.CALCULATION, TableTypes.FieldType.NUMBER].includes(field.type)
	) {
		return value || typeof value === 'number' ? fixedValue : null
	}

	if (SINGLE_SELECT_FIELDS.includes(field.type)) {
		const _selectedOption = mappedValueOptions[field._id]?.get(value)
		if (!_selectedOption) {
			return value
		}
		return _selectedOption
	}

	if (field.type === TableTypes.FieldType.BOOLEAN) {
		if (value === 'FALSE') {
			return false
		}
		return !!value
	}

	if (field.type === TableTypes.FieldType.OPTIONS && value?.answerType) {
		if (
			[TableTypes.AnswerType.SingleSelect, TableTypes.AnswerType.MultiSelect].includes(
				value.answerType,
			)
		) {
			const options = convertRowValueToArray(value?.options)
			return value.answerType + ': ' + options.length + ' options'
		}
		return value.answerType
	}

	return value ?? ''
}

type OperatorFunction = (columnType: GridColType, cellValue: any, filterValue: any) => boolean

const operatorFunctions: Record<string, OperatorFunction> = {
	contains: (columnType, cellValue, filterValue) =>
		String(cellValue).toLowerCase().includes(String(filterValue).toLowerCase()),
	doesNotContain: (columnType, cellValue, filterValue) =>
		!String(cellValue).toLowerCase().includes(String(filterValue).toLowerCase()),
	equals: (columnType, cellValue, filterValue) => {
		if (columnType === 'number') {
			return Number(cellValue) === Number(filterValue)
		}
		return String(cellValue) === String(filterValue)
	},
	doesNotEqual: (columnType, cellValue, filterValue) => {
		if (columnType === 'number') {
			return Number(cellValue) !== Number(filterValue)
		}
		return String(cellValue) !== String(filterValue)
	},
	startsWith: (columnType, cellValue, filterValue) =>
		String(cellValue).toLowerCase().startsWith(String(filterValue).toLowerCase()),
	endsWith: (columnType, cellValue, filterValue) =>
		String(cellValue).toLowerCase().endsWith(String(filterValue).toLowerCase()),
	isEmpty: (columnType, cellValue) => !cellValue || String(cellValue).trim() === '',
	isNotEmpty: (columnType, cellValue) => Boolean(cellValue) && String(cellValue).trim() !== '',
	isAnyOf: (columnType, cellValue, filterValue) => !!filterValue?.includes(cellValue),

	// Number Operators
	'=': (columnType, cellValue, filterValue) => Number(cellValue) === Number(filterValue),
	'!=': (columnType, cellValue, filterValue) => Number(cellValue) !== Number(filterValue),
	'>': (columnType, cellValue, filterValue) => Number(cellValue) > Number(filterValue),
	'>=': (columnType, cellValue, filterValue) => Number(cellValue) >= Number(filterValue),
	'<': (columnType, cellValue, filterValue) => Number(cellValue) < Number(filterValue),
	'<=': (columnType, cellValue, filterValue) => Number(cellValue) <= Number(filterValue),

	// Mixed
	is: (columnType, cellValue, filterValue) => {
		if (columnType === 'date') {
			return dayjs(cellValue).isSame(dayjs(filterValue), 'day')
		}
		if (columnType === 'number') {
			return Number(cellValue) === Number(filterValue)
		}

		if (columnType === 'boolean' && typeof cellValue === 'string') {
			return _toLower(cellValue) === _toLower(String(filterValue))
		}

		return String(cellValue) === String(filterValue)
	},
	not: (columnType, cellValue, filterValue) => {
		if (columnType === 'date') {
			return !dayjs(cellValue).isSame(dayjs(filterValue), 'day')
		}
		if (columnType === 'number') {
			return Number(cellValue) !== Number(filterValue)
		}
		return cellValue !== filterValue
	},

	// Date Operators (using dayjs)
	after: (columnType, cellValue, filterValue) =>
		dayjs(cellValue).isAfter(dayjs(filterValue), 'day'),
	onOrAfter: (columnType, cellValue, filterValue) =>
		dayjs(cellValue).isSame(dayjs(filterValue), 'day') ||
		dayjs(cellValue).isAfter(dayjs(filterValue), 'day'),
	before: (columnType, cellValue, filterValue) =>
		dayjs(cellValue).isBefore(dayjs(filterValue), 'day'),
	onOrBefore: (columnType, cellValue, filterValue) =>
		dayjs(cellValue).isSame(dayjs(filterValue), 'day') ||
		dayjs(cellValue).isBefore(dayjs(filterValue), 'day'),
}

export const applyFilterItem = (
	columnType: GridColType,
	operator: string,
	value: any,
	filterValue: any,
) => {
	const filterFn = operatorFunctions[operator]
	if (!filterFn) {
		return true
	}

	return filterFn(columnType, value, filterValue)
}

function applyTableFilterItem(
	columnType: GridColType,
	row: GridRowModel,
	filterItem: GridFilterItem,
): boolean {
	const { field, operator, value } = filterItem
	const cellValue = row[field]
	return applyFilterItem(columnType, operator, cellValue, value)
}

export const applyFilterModelToRow = ({
	columns,
	row,
	filterModel,
}: {
	columns: {
		_id: string
		type?: GridColType
	}[]
	row: GridRowModel
	filterModel: GridFilterModel
}): boolean => {
	const { items, logicOperator } = filterModel
	const filterOperation = (filterItem: GridFilterItem) => {
		const columnType = columns.find((column) => column._id === filterItem.field)?.type
		if (!columnType) return true
		return applyTableFilterItem(columnType, row, filterItem)
	}
	if (logicOperator === 'or') {
		return items.some(filterOperation)
	}

	return items.every(filterOperation)
}

export const convertRowValueToArray = (rowValue: any): TableTypes.ListOption[] => {
	if (_isArray(rowValue)) {
		return rowValue
	}
	return []
}

type LinkedTable = TablesSdk.MenuTable & {
	prefix: string
}

export const getLinkedTable = (
	table: Pick<TablesSdk.MenuTable, '_id' | 'type' | 'questionaire_reference_table'>,
	tableList: TablesSdk.MenuTable[],
): LinkedTable | undefined => {
	let linkedTable: TablesSdk.MenuTable | undefined
	if (table.type === TableTypes.TableType.Questionaire) {
		linkedTable = tableList.find((_table) => _table.questionaire_reference_table === table._id)
	} else {
		const reference = table.questionaire_reference_table
		linkedTable = tableList.find((_table) => _table._id === reference)
	}
	if (!linkedTable) {
		return
	}
	return {
		...linkedTable,
		prefix: linkedTable.type === TableTypes.TableType.Questionaire ? 'Questionaire' : 'Linked to',
	}
}

export const getRowDifference = (oldRow: any, newRow: any) => {
	const keys = _union(_keys(oldRow), _keys(newRow))

	return _reduce(
		keys,
		(result: string[], key) => {
			if (!_isEqual(oldRow[key], newRow[key])) {
				result.push(key)
			}
			return result
		},
		[],
	)
}

export const stringsMatch = (str1: string, str2: string) => {
	const normalize = (str: string) => _trim(_replace(str, /\r/g, ''))
	return _isEqual(normalize(str1), normalize(str2))
}
