import React from 'react'
import Papa, { ParseResult } from 'papaparse'
import { v4 } from 'uuid'

export const handlePasteOptions = (
	e: React.ClipboardEvent<HTMLDivElement>,
	_existingOptions:
		| {
				_id: string
				label: string
		  }[]
		| string,
	startingIndex: number,
):
	| {
			_id: string
			label: string
	  }[]
	| string => {
	e.preventDefault()
	e.stopPropagation()
	const clipboardData = e.clipboardData
	const pastedData = clipboardData.getData('text')
	if (!pastedData) {
		return []
	}
	if (typeof _existingOptions === 'string') {
		return _existingOptions
	}
	const newOptions = [..._existingOptions]

	Papa.parse(pastedData as any, {
		delimiter: '\t',
		newline: '\n',
		skipEmptyLines: true,
		quoteChar: '"',
		complete: (results: ParseResult<string[]>) => {
			const rowsByCell = results.data as string[][]
			const allValues = rowsByCell.map((_row) => _row[0])
			let indexTicker = startingIndex
			for (const data of allValues) {
				if (newOptions[indexTicker]) {
					newOptions[indexTicker] = { ...newOptions[indexTicker], label: data }
				} else {
					newOptions.push({ _id: v4(), label: data })
				}
				indexTicker++
			}
		},
	})
	return newOptions
}
