import React, { ComponentType, ReactNode, useMemo } from 'react'
import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select'
import FormControl, { FormControlProps } from '@mui/material/FormControl'
import { TypographyProps } from '@mui/material/Typography'
import FormHelperText from '@mui/material/FormHelperText'
import MenuItem from '@mui/material/MenuItem'
import Box, { BoxProps } from '@mui/material/Box'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import _orderBy from 'lodash/orderBy'
import _isArray from 'lodash/isArray'

import { ChevronDown } from 'src/assets/icons'
import { colors, palette } from 'src/theme/colors'

import { Text } from '../text'

export type SelectProps = Omit<MuiSelectProps, 'variant'> & {
	containerProps?: BoxProps
	label?: string
	labelColor?: TypographyProps['color']
	options: {
		_id: string
		label: string | ReactNode
		bold?: boolean
		disabled?: boolean
	}[]
	size?: FormControlProps['size']
	fontSize?: number
	helperText?: string | ReactNode
	helperError?: boolean
	isLoading?: boolean
	variant?: MuiSelectProps['variant']
	disableOrdering?: boolean
	withNoneOption?: boolean
	specialOption?: string
	onAddOptionSelect?: () => void
	noneLabel?: string
}

export const Select: ComponentType<SelectProps> = ({
	containerProps,
	required,
	label = '',
	labelColor = colors.neutral['80'],
	options,
	error,
	size,
	fontSize = 14,
	helperText,
	helperError,
	isLoading,
	renderValue,
	disableOrdering,
	withNoneOption,
	specialOption,
	onAddOptionSelect,
	noneLabel = 'None',
	...props
}) => {
	const mappedOptions = useMemo(() => {
		return new Map(options.map((item) => [item._id, item.label]))
	}, [options])

	const value = useMemo(() => {
		if (props.multiple) {
			if (!_isArray(props.value)) return []
			return props.value
		}
		return props.value ?? ''
	}, [props.value, props.multiple])

	const sortedOptions = useMemo(() => {
		if (disableOrdering) return options
		return _orderBy(options, ['label'], ['asc'])
	}, [options, disableOrdering])

	return (
		<Box {...containerProps}>
			<Text fontSize={14} color={labelColor} fontWeight={500}>
				{label} {!!required && '*'}
			</Text>
			<FormControl fullWidth required={required} disabled={props.disabled} size={size}>
				<MuiSelect
					error={error}
					placeholder={label ?? props.placeholder}
					displayEmpty={!!props.placeholder}
					sx={{
						bgcolor: 'white',
						'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
							borderColor: palette.borders.main,
						},
						height: 'auto',
					}}
					IconComponent={() => <ChevronDown style={{ marginRight: 12 }} />}
					renderValue={(selected: any) => {
						if (renderValue) {
							return renderValue(selected)
						}
						if (_isArray(selected)) {
							const selectedLabels = selected.map((item: string) => mappedOptions.get(item))
							return _orderBy(selectedLabels).join(', ')
						}
						return mappedOptions.get(selected)
					}}
					required={required}
					{...props}
				>
					{!!props.placeholder && (
						<MenuItem value="" disabled sx={{ opacity: 0.5, fontSize }}>
							{props.placeholder}
						</MenuItem>
					)}
					{!!withNoneOption && (
						<MenuItem value="" sx={{ fontSize, fontWeight: 'normal' }}>
							<em>{noneLabel}</em>
						</MenuItem>
					)}
					{!!onAddOptionSelect && (
						<MenuItem
							value="new"
							sx={{ fontSize, fontWeight: 'normal' }}
							onClick={onAddOptionSelect}
						>
							<em>+ Add new</em>
						</MenuItem>
					)}
					{!!specialOption && (
						<MenuItem value={specialOption} sx={{ fontSize, fontWeight: 'normal' }}>
							<em>{specialOption}</em>
						</MenuItem>
					)}
					{sortedOptions.map((item) => (
						<MenuItem
							key={item._id}
							value={item._id}
							disabled={item.disabled}
							sx={{ fontSize, fontWeight: item.bold ? 'bold' : 'normal' }}
						>
							{!!props.multiple && (
								<Checkbox checked={(value as string[])?.indexOf(item._id) > -1} size="small" />
							)}
							{props.multiple ? (
								<ListItemText primary={item.label} primaryTypographyProps={{ fontSize: 14 }} />
							) : (
								item.label
							)}
						</MenuItem>
					))}
				</MuiSelect>
				<FormHelperText error={helperError || error}>
					{isLoading ? 'Loading...' : helperText}
				</FormHelperText>
			</FormControl>
		</Box>
	)
}
