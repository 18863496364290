import { ComponentType, useCallback, useContext, useEffect, useState } from 'react'
import { TablesSdk } from '@cango-app/sdk'
import { TableTypes } from '@cango-app/types'
import { useSelector } from 'react-redux'
import { v4 } from 'uuid'
import { GridFilterModel } from '@mui/x-data-grid-premium'

import { selectors as authSelectors } from 'src/store/modules/auth'
import { TableContext } from 'src/providers'
import { getLayout } from 'src/modules/tables/questionaire-logic/get-layout'
import { applyFilterItem, applyFilterModelToRow, columnValueGetter } from 'src/modules/tables/utils'
import { sortNodesWithDescendants } from 'src/modules/tables/questionaire-logic/utils'
import { showSnackbar } from 'src/helpers/snackbarManager'
import {
	BasicQuestionaireData,
	CangoQuestionaireNode,
} from 'src/modules/tables/questionaire-logic/types'
import {
	ColumnFilterList,
	MappedValueOptions,
	ResolvedRowData,
} from 'src/providers/table-provider/types'

import { Box } from '../box'

import { QuestionFlowContainer } from './question-flow-container'
import { Question } from './types'

type QuestionFlowLogicContainerProps = {
	filters: GridFilterModel
	acceptedQuestionColumns: string[]
	questionScope: string[]
}

const getQuestionParents = ({
	questionId,
	visitedNodes,
	questions,
}: {
	questionId: string
	visitedNodes?: Set<string>
	questions: TableTypes.Record[]
}): string[] => {
	if (!visitedNodes) {
		visitedNodes = new Set<string>()
	}
	if (visitedNodes.has(questionId)) {
		return []
	}
	visitedNodes.add(questionId)
	const parents = questions.filter((_ques) =>
		_ques.descendants?.some((_desc) => _desc.row === questionId),
	)
	let parentIds = parents.map((_parent) => _parent._id)
	if (parents.length) {
		parentIds = [
			...parents
				.map((_parent) =>
					getQuestionParents({
						questionId: _parent._id,
						visitedNodes,
						questions,
					}),
				)
				.flat(),
			...parentIds,
		]
	}

	return parentIds
}

const getMatchingCondition = (
	question: Question,
	answer: TableTypes.QuestionaireResponseItem[],
) => {
	const childrenWithConditions = question.descendants.filter(
		(_descendant) => _descendant.option_condition?.values.length,
	)

	let successfulCondition = childrenWithConditions.find((_desc) => {
		const optionCondition = _desc.option_condition
		if (!optionCondition) {
			return
		}

		return optionCondition.values.some((_val) => {
			return answer.some((_ans) =>
				applyFilterItem('string', optionCondition.operator, _ans.option_id ?? _ans.text, _val),
			)
		})
	})

	if (!successfulCondition) {
		successfulCondition = question.descendants.find(
			(_descendant) => !_descendant.option_condition?.values.length,
		)
	}

	return successfulCondition
}

const convertQuestionTableIntoQuestions = ({
	questionTable,
	rowsAfterFilter,
	mappedColumns,
	questionScope,
	questionaireAnswers,
	acceptedQuestionColumns,
	mappedValueOptions,
}: {
	questionTable: TableTypes.CangoTable
	rowsAfterFilter: ResolvedRowData[]
	mappedColumns: Map<string, TableTypes.Field>
	questionScope: string[]
	questionaireAnswers: TableTypes.QuestionaireAnswer[]
	acceptedQuestionColumns: string[]
	mappedValueOptions: MappedValueOptions
}): Question[] => {
	const questionTableQuestionId = questionTable.principal_field ?? ''
	const questionTableOptionsId =
		questionTable.fields.find((_field) => _field.type === TableTypes.FieldType.OPTIONS)?._id ?? ''

	const topsortedQuestionNodes = getLayout({
		rows: questionTable.records,
		questionColumnId: questionTableQuestionId,
		optionsColumnId: questionTableOptionsId,
	})

	const questionColumns = [...mappedColumns.values()].filter(
		(_col) =>
			_col.type === TableTypes.FieldType.QUESTIONAIRE_REFERENCE &&
			(!acceptedQuestionColumns.length || acceptedQuestionColumns.includes(_col._id)),
	)
	const questionColumnIds = questionColumns.map((_col) => _col._id)

	const scopeAskedQuestions = new Map<string, Set<string>>()

	const createScopeKey = (row: ResolvedRowData): string => {
		return questionScope.map((_colId) => row[_colId] || '').join('>')
	}

	return rowsAfterFilter.reduce((_questions: Question[], _row) => {
		const scopeKey = createScopeKey(_row)
		const _rowQuestions = questionColumnIds.reduce(
			(
				_acc: { colId: string; question: CangoQuestionaireNode<BasicQuestionaireData> }[],
				_colId,
			) => {
				const questionId = _row[_colId] as string | undefined
				if (!questionId) {
					return _acc
				}

				const getQuestion = (question_id: string) => {
					return topsortedQuestionNodes.nodes.find((_node) => _node.id === question_id)
				}

				const question = getQuestion(questionId)

				if (!question) {
					return _acc
				}

				const questionParents = getQuestionParents({
					questionId,
					questions: questionTable.records,
				})

				questionParents.forEach((_parent) => {
					const parentQuestion = getQuestion(_parent)
					if (parentQuestion) {
						_acc.push({
							colId: _colId,
							question: parentQuestion,
						})
					}
				})

				_acc.push({
					colId: _colId,
					question: question,
				})
				return _acc
			},
			[],
		)

		const constructedQuestions = _rowQuestions.reduce((_acc: Question[], _rowQuestion) => {
			const question = _rowQuestion.question
			if (questionScope.length === 0 && _acc.some((_q) => _q._id === question.id)) {
				return _acc
			}

			const questionAnsweredInHigherScope = questionaireAnswers.some((_answer) => {
				return (
					_answer.question_id === question.id &&
					_answer.scope.length < questionScope.length &&
					_answer.scope.every((_scope) => {
						return questionScope.some((_colId) => _row[_colId] === _scope.value)
					})
				)
			})

			if (questionAnsweredInHigherScope) {
				return _acc
			}

			const questionRow = questionTable.records.find((_record) => _record._id === question.id)
			if (!scopeAskedQuestions.has(scopeKey)) {
				scopeAskedQuestions.set(scopeKey, new Set<string>())
			}

			const askedInScope = scopeAskedQuestions.get(scopeKey)
			if (!askedInScope || askedInScope.has(question.id)) {
				return _acc
			}

			askedInScope.add(question.id)

			const questionId = `${_row._id}-${_rowQuestion.colId}-${questionScope.join('-')}`

			_acc.push({
				_id: questionId,
				info: questionScope.map((_colId) => {
					const mainTableColumn = mappedColumns.get(_colId)
					if (!mainTableColumn) return ''
					return `${mainTableColumn.name}: ${columnValueGetter(_row?.[_colId], mainTableColumn, mappedValueOptions)}`
				}),
				question: question.data.label,
				type: question.data.answerConfig.answerType,
				options: question.data.answerConfig.options,
				descendants: questionRow?.descendants ?? [],
				rowId: _row._id,
				columnId: _rowQuestion.colId,
				questionRowId: question.id,
				hierarchy: questionScope.reduce((_acc: Record<string, any>, _colId) => {
					return {
						..._acc,
						[_colId]: _row[_colId],
					}
				}, {}),
			})
			return _acc
		}, [])

		return [..._questions, ...constructedQuestions]
	}, [])
}

const getQuestionaireQuestions = ({
	answers,
	questionTable,
	filters,
	mappedColumns,
	questionScope,
	acceptedQuestionColumns,
	columnFilterList,
	records,
	mappedValueOptions,
}: {
	answers: TableTypes.QuestionaireAnswer[]
	questionTable: TableTypes.CangoTable
	filters: GridFilterModel
	mappedColumns: Map<string, TableTypes.Field>
	questionScope: string[]
	acceptedQuestionColumns: string[]
	columnFilterList: ColumnFilterList
	records: ResolvedRowData[]
	mappedValueOptions: MappedValueOptions
}): Question[] => {
	const rowsAfterFilter = [...(records ?? [])].filter((_row) => {
		if (!filters.items.length) return true
		return applyFilterModelToRow({
			columns: columnFilterList,
			row: _row,
			filterModel: filters,
		})
	})

	return convertQuestionTableIntoQuestions({
		questionTable: questionTable,
		rowsAfterFilter,
		mappedColumns,
		questionScope,
		questionaireAnswers: answers ?? [],
		acceptedQuestionColumns,
		mappedValueOptions,
	})
}

export const QuestionFlowLogicContainer: ComponentType<QuestionFlowLogicContainerProps> = ({
	filters,
	acceptedQuestionColumns,
	questionScope,
}) => {
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const {
		tableConfig,
		mappedColumns,
		isLoadingTable,
		columnFilterList,
		resolvedRows,
		updateTableConfig,
		mappedValueOptions,
	} = useContext(TableContext)
	const answers = [...(tableConfig?.questionaire_answers ?? [])]
	const [isLoadingQuestionaire, setIsLoadingQuestionaire] = useState(false)
	const [questions, setQuestions] = useState<Question[]>([])
	const [activeQuestionIndex, setActiveQuestionIndex] = useState(0)
	const [stepHistory, setStepHistory] = useState<number[]>([])

	const fetchTable = useCallback(async () => {
		setIsLoadingQuestionaire(true)
		try {
			if (!tableConfig?.questionaire_reference_table) {
				throw new Error('Table not found')
			}
			const { table: questionTable } = await TablesSdk.getTable(
				import.meta.env.VITE_API as string,
				authHeaders,
				tableConfig.questionaire_reference_table,
			)

			const questions = getQuestionaireQuestions({
				answers,
				questionTable,
				filters,
				mappedColumns,
				questionScope,
				acceptedQuestionColumns,
				columnFilterList,
				records: resolvedRows,
				mappedValueOptions,
			})

			const sortedQuestions = sortNodesWithDescendants(questions, questionScope)

			setQuestions(sortedQuestions)
		} catch (error) {
			showSnackbar('Failed to generate questionaire', { variant: 'error' })
		} finally {
			setIsLoadingQuestionaire(false)
		}
	}, [filters, columnFilterList, resolvedRows, answers])

	const goToNextQuestion = (question: Question, answer: TableTypes.QuestionaireResponseItem[]) => {
		const successfulCondition = getMatchingCondition(question, answer)

		const updateActiveIndex = (index: number) => {
			setActiveQuestionIndex(index)
			setStepHistory((prev) => [...prev, activeQuestionIndex])
		}

		if (successfulCondition) {
			const nextQuestionIndex = questions.findIndex(
				(_ques) => _ques.questionRowId === successfulCondition.row,
			)

			if (nextQuestionIndex >= 0) {
				updateActiveIndex(nextQuestionIndex)
			} else {
				const nextQuestionOutsideScope = questions[activeQuestionIndex + 1]
				if (nextQuestionOutsideScope) {
					updateActiveIndex(activeQuestionIndex + 1)
				}
			}
		} else {
			const nextQuestionOutsideScope = questions[activeQuestionIndex + 1]
			if (nextQuestionOutsideScope) {
				updateActiveIndex(activeQuestionIndex + 1)
			}
		}
	}

	const handleAnswerClick = useCallback(
		(
			answer: TableTypes.QuestionaireResponseItem[],
			existingResponse: TableTypes.QuestionaireAnswer | undefined,
		) => {
			if (questions.length === 0 || !tableConfig) return
			const question = questions[activeQuestionIndex]
			const questionScope = Object.entries(question.hierarchy).map(([column_id, value]) => ({
				column_id,
				value,
			}))
			let newAnswers = [...answers]
			const existingAnswerIndex = newAnswers.findIndex((_ans) => _ans._id === existingResponse?._id)
			if (existingAnswerIndex >= 0) {
				newAnswers[existingAnswerIndex].answer = answer
			} else {
				newAnswers.push({
					_id: v4(),
					question_id: question.questionRowId,
					answer,
					scope: questionScope,
				})
			}

			const childrenRowIds = (
				questionId: string,
				visitedQuestions = new Set<string>(),
			): string[] => {
				if (visitedQuestions.has(questionId)) {
					return []
				}
				visitedQuestions.add(questionId)
				const parentQuestion = questions.find((_ques) => questionId === _ques.questionRowId)
				if (!parentQuestion) {
					return []
				}
				return parentQuestion.descendants.reduce((_childIds: string[], _desc) => {
					if (!_desc.row) {
						return _childIds
					}
					return [..._childIds, _desc.row, ...childrenRowIds(_desc.row, visitedQuestions)]
				}, [])
			}

			const matchingCondition = getMatchingCondition(question, answer)
			const allFailedConditions = question.descendants.filter(
				(_desc) => _desc._id !== matchingCondition?._id,
			)

			const childrenOfFailedConditions = allFailedConditions.reduce((_acc: string[], _desc) => {
				if (!_desc.row) {
					return _acc
				}
				return [..._acc, _desc.row, ...childrenRowIds(_desc.row)]
			}, [])

			newAnswers = newAnswers.filter((_ans) => {
				if (!childrenOfFailedConditions.includes(_ans.question_id)) {
					return true
				}
				if (_ans.scope.length < questionScope.length) {
					return true
				}

				return !_ans.scope.every(({ column_id, value }) => question.hierarchy[column_id] === value)
			})

			updateTableConfig({
				...tableConfig,
				questionaire_answers: [...newAnswers],
			})

			if (question.type === TableTypes.AnswerType.SingleSelect) {
				goToNextQuestion(question, answer)
			}
		},
		[questions, tableConfig, activeQuestionIndex, answers],
	)

	useEffect(() => {
		if (!isLoadingTable && !!tableConfig) {
			fetchTable()
		}
	}, [isLoadingTable])

	if (isLoadingQuestionaire || isLoadingTable || !questions.length) {
		return (
			<Box display="flex" flexDirection="row" alignItems="center">
				{isLoadingQuestionaire
					? 'Building questionaire...'
					: isLoadingTable
						? 'Fetching table...'
						: 'No questions to display'}
			</Box>
		)
	}

	return (
		<QuestionFlowContainer
			questions={questions}
			onAnswerClick={handleAnswerClick}
			activeQuestionIndex={activeQuestionIndex}
			goBack={() => {
				const lastStep = stepHistory.pop()
				if (lastStep !== undefined) {
					setActiveQuestionIndex(lastStep)
				}
			}}
			goForward={goToNextQuestion}
		/>
	)
}
