import { Badge, Stack } from '@mui/material'
import { GridCellParams } from '@mui/x-data-grid-premium'
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium'
import { useCallback, useContext, useMemo, useState } from 'react'
import { TableTypes } from '@cango-app/types'

import {
	Button,
	Modal,
	Text,
	Box,
	AttachedFile,
	IconButton,
	DriveUploadButton,
} from 'src/components'
import { useParentFolderFiles } from 'src/components/drive-upload/use-parent-folder-files'
import { DriveFilesContext, TableContext } from 'src/providers'
import { TrashIcon } from 'src/assets/icons'
import { showSnackbar } from 'src/helpers/snackbarManager'

type Props = {
	cell: GridCellParams
	tableRef: React.MutableRefObject<GridApiPremium | null>
	field: TableTypes.Field
}

export const DriveFile = ({ cell, tableRef, field }: Props) => {
	const [openFileModal, setOpenFileModal] = useState(false)
	const [openDriveUpload, setOpenDriveUpload] = useState(false)
	const { tableConfig } = useContext(TableContext)
	const { parentFolderId, parentFolderName } = useContext(DriveFilesContext)

	const { allFiles, isLoadingFiles } = useParentFolderFiles(
		parentFolderId ?? '',
		parentFolderName ?? '',
	)
	const { updateRecords, mappedRecords } = useContext(TableContext)

	const files = useMemo(() => {
		if (cell.rowNode.type === 'group') {
			const rowIdsInGroup = tableRef.current?.getRowGroupChildren({ groupId: cell.id })
			if (!rowIdsInGroup?.length) return []
			const filesInCell = rowIdsInGroup.flatMap((rowId) => {
				const record = mappedRecords.get(rowId as string)
				const file = record?.data?.[field._id]
				if (!file) return []
				return JSON.parse(`${file}`)
			})
			if (!filesInCell) return []
			return allFiles.filter(({ id }) => filesInCell.includes(id))
		}
		const filesInCell = typeof cell.value === 'string' ? (JSON.parse(cell.value) as string[]) : []
		return allFiles.filter(({ id }) => filesInCell.includes(id))
	}, [cell, allFiles])

	const currentFileIds = useMemo(() => files.map(({ id }) => id), [files])

	const updateFiles = useCallback(
		async (newValues: string[]) => {
			const stringifyFiles = JSON.stringify(newValues)
			const isGroupedRow = cell.rowNode.type === 'group'

			if (isGroupedRow) {
				const rowIds = tableRef.current?.getRowGroupChildren({ groupId: cell.id })
				if (!rowIds?.length) {
					showSnackbar('No record selected', { variant: 'error' })
					return
				}
				const recordsInGroup = rowIds.map((rowId) =>
					mappedRecords.get(rowId as string),
				) as TableTypes.Record[]
				const rows = recordsInGroup.map((currentRecord) => {
					return {
						oldRecord: currentRecord,
						newRecord: {
							...currentRecord,
							data: {
								...currentRecord.data,
								[field._id]: stringifyFiles,
							},
						},
					}
				})
				await updateRecords({
					rows,
					save: true,
				})
				return
			}
			const record = mappedRecords.get(cell.row._id)
			if (!record) {
				showSnackbar('No record selected', { variant: 'error' })
				return
			}
			await updateRecords({
				rows: [
					{
						oldRecord: record,
						newRecord: {
							...record,
							data: {
								...record.data,
								[field._id]: stringifyFiles,
							},
						},
					},
				],
				save: true,
			})
		},
		[updateRecords, mappedRecords],
	)

	const removeFile = useCallback(
		async (fileId: string) => {
			const filteredFileIds = currentFileIds.filter((id) => fileId !== id)
			await updateFiles(filteredFileIds)
		},
		[updateFiles, currentFileIds],
	)
	const onUploadedFile = useCallback(
		async (uploadedFileIds: string[]) => {
			if (!tableRef.current) return
			await updateFiles(uploadedFileIds)
			setOpenDriveUpload(false)
		},
		[updateFiles, currentFileIds],
	)
	return (
		<>
			<Modal open={openDriveUpload} onClose={() => setOpenDriveUpload(false)}>
				<Box>
					<DriveUploadButton
						containerStyles={{ mt: currentFileIds.length ? 1 : 0 }}
						selectedFilesIds={currentFileIds}
						isLoading={isLoadingFiles}
						onFileIdsChange={onUploadedFile}
					/>
				</Box>
			</Modal>
			<Modal open={openFileModal} onClick={() => setOpenFileModal(false)}>
				<Box height="550px" width="500px" overflow="hidden">
					<Stack direction="column" spacing={2} height="100%">
						<Text variant="h4">Files:</Text>
						{files.length === 0 ? (
							<>
								<Text>No Files found</Text>
								<Box onClick={(e) => e.stopPropagation()}>
									<DriveUploadButton
										containerStyles={{ flex: 1 }}
										selectedFilesIds={currentFileIds}
										ctaVariant={currentFileIds.length ? 'replaceIcon' : undefined}
										isLoading={isLoadingFiles}
										onFileIdsChange={onUploadedFile}
									/>
								</Box>
							</>
						) : (
							<>
								<Stack
									direction="column"
									spacing={1}
									height="450px"
									sx={{
										overflowY: 'auto',
									}}
								>
									{files.map((file) => {
										return (
											<Stack
												key={file.id}
												direction="row"
												justifyContent="space-between"
												alignItems="center"
											>
												<Box width="85%">
													<AttachedFile
														key={file.id}
														fileId={file.id}
														_file={allFiles.find((_file) => _file.id === file.id)}
														parentFolderId={parentFolderId}
														parentFolderName={parentFolderName}
													/>
												</Box>
												<IconButton
													onClick={(e) => {
														removeFile(file.id)
														e.stopPropagation()
													}}
												>
													<TrashIcon />
												</IconButton>
											</Stack>
										)
									})}
								</Stack>
								<Stack direction="column" spacing={1} onClick={(e) => e.stopPropagation()}>
									<DriveUploadButton
										containerStyles={{ flex: 1 }}
										selectedFilesIds={currentFileIds}
										ctaVariant={currentFileIds.length ? 'replaceIcon' : undefined}
										isLoading={isLoadingFiles}
										onFileIdsChange={onUploadedFile}
									/>
									<Button onClick={() => setOpenFileModal(false)}>Close</Button>
								</Stack>
							</>
						)}
					</Stack>
				</Box>
			</Modal>
			{tableConfig?.project_id ? (
				<Button
					fullWidth
					variant="text"
					disabled={!parentFolderId && !tableConfig.project_id}
					onClick={() => setOpenFileModal(true)}
					sx={{
						textDecoration: 'underline',
						cursor: 'pointer',
						minWidth: 'fit-content',
					}}
				>
					<Badge badgeContent={currentFileIds.length ?? 0} color="primary">
						See File
					</Badge>
				</Button>
			) : (
				<Text tooltip="Only editable in project's table" variant="caption">
					Uneditable
				</Text>
			)}
		</>
	)
}
