import {
	TableTypes,
	V3BlueprintTypes,
	V3ClientTypes,
	V3ProjectTypes,
	V3ServerTypes,
	ChainTypes,
} from '@cango-app/types'
import { V3ProjectSdk } from '@cango-app/sdk'
import { GridColType, GridFilterModel } from '@mui/x-data-grid-premium'
import { v4 } from 'uuid'
import _isString from 'lodash/isString'
import _cloneDeep from 'lodash/cloneDeep'
import _uniqBy from 'lodash/uniqBy'
import _omit from 'lodash/omit'
import _filter from 'lodash/filter'

import { applyFilterModelToRow, getFilteredChains, getFilters } from 'src/modules/tables/utils'
import { ResolvedRowData } from 'src/providers/table-provider/types'
import { CompletingTask } from 'src/modules/my-tasks-v3/components/complete-task-cta/types'

export const getDatabaseRowUpdates = ({
	resolvedRows,
	task,
	columnsWithFilterType,
	descendants,
	newSelectedOptions,
	previousSelectedOptions,
	mappedRecords,
	mappedColumns,
	customFields,
	blueprintRecords,
}: {
	resolvedRows: ResolvedRowData[]
	task: V3ClientTypes.Project.Task
	columnsWithFilterType: { _id: string; type: GridColType }[]
	descendants: V3ClientTypes.Project.ProjectDescendant[]
	previousSelectedOptions: { _id: string; label: string }[]
	newSelectedOptions: { _id: string; label: string }[]
	mappedRecords: Map<string, TableTypes.Record>
	mappedColumns: Map<string, TableTypes.Field>
	customFields?: Map<string, string>
	blueprintRecords: Map<string, TableTypes.Record>
}): {
	newRows: TableTypes.Record[]
	rowUpdates: { newRecord: TableTypes.Record; oldRecord: TableTypes.Record }[]
	rowsToReset: string[]
} => {
	const isMenu = task?.step?.isMenu ?? !!task?.isMenu
	const _newRows: TableTypes.Record[] = []
	const rowUpdates: Record<string, { newRecord: TableTypes.Record; oldRecord: TableTypes.Record }> =
		{}
	const rowsToReset: string[] = []

	descendants.forEach((_desc) => {
		if (!task) {
			return
		}
		const databaseChanges = _desc.database_changes
		if (!databaseChanges) {
			return
		}

		const nextChains = getFilteredChains(_desc, task.chain)

		const filters = getFilters({
			chains: nextChains,
			mappedColumns,
		})

		if (_isString(task.step?.complete_options?.options) && task.isMultiUse && isMenu) {
			const onlyNewOrChangedOptions = newSelectedOptions.filter(
				(_option) =>
					!task.lifecycle.completed_options.some(
						(_completedOption) => _completedOption._id === _option._id,
					),
			)
			filters.push({
				field: task.step.complete_options.options,
				operator: 'isAnyOf',
				value: onlyNewOrChangedOptions.map(({ label }) => label),
			})
		}

		const filteredRows = resolvedRows.filter((_row) => {
			if (filters.length) {
				return applyFilterModelToRow({
					columns: columnsWithFilterType,
					row: _row,
					filterModel: { items: filters },
				})
			}
			return true
		})

		const records = filteredRows.reduce((_acc: TableTypes.Record[], _row) => {
			const record = mappedRecords.get(_row._id)
			if (record) {
				_acc.push(record)
			}
			return _acc
		}, [])

		records.forEach((record) => {
			const isSelected = newSelectedOptions.some((_newOption) => {
				if (!_isString(task.step?.complete_options?.options)) {
					return true
				}
				return (
					_newOption.label === record.data[task.step?.complete_options.options] ||
					_newOption._id === record.data[task.step?.complete_options.options]
				)
			})

			const wasPreviouslySelected = previousSelectedOptions.some((_oldOption) => {
				if (!_isString(task.step?.complete_options?.options)) {
					return true
				}
				return (
					_oldOption.label === record.data[task.step?.complete_options.options] ||
					_oldOption._id === record.data[task.step?.complete_options.options]
				)
			})

			if (!isSelected && wasPreviouslySelected) {
				rowsToReset.push(record._id)
				return
			}

			if (!isSelected || (wasPreviouslySelected && isSelected)) {
				return
			}

			const customDatabaseChanges = databaseChanges.filter(
				({ operator }) => operator === 'custom_input',
			)

			if (!isMenu && record && customDatabaseChanges.length) {
				const rowHasExistingValue = customDatabaseChanges.every(({ field, is_required }) => {
					if (!is_required) {
						return true
					}
					const val = record.data?.[field] ?? record.overrides?.[field]
					return val !== null && val !== undefined && val !== ''
				})

				const blueprintRecord = blueprintRecords.get(record._id)
				if (rowHasExistingValue && blueprintRecord) {
					const newRow = { ...blueprintRecord, _id: v4() }
					task?.chain?.database_chain_logic?.filters.items.forEach((_filter) => {
						if (_filter.operator === 'custom_input') {
							return
						}
						newRow.data[_filter.field] = record.data[_filter.field]
					})
					databaseChanges.forEach((_mod) => {
						if (mappedColumns.get(_mod.field)?.type === TableTypes.FieldType.CALCULATION) {
							newRow.overrides = {
								...newRow.overrides,
								[_mod.field]: customFields?.get(_mod.field) ?? _mod.value,
							}
							return
						}
						newRow.data[_mod.field] = _mod.value
					})
					_newRows.push(newRow)
					return
				}
			}

			const requiredDatabaseChanges = customDatabaseChanges.filter(({ is_required }) => is_required)
			const rowAlreadyEditted = requiredDatabaseChanges.every(({ field, operator, value }) => {
				if (operator === 'custom_input') {
					const val = record.data?.[field] ?? record.overrides?.[field]
					return val !== null && val !== undefined && val !== ''
				}
				return record.data[field] === value || record.overrides?.[field] === value
			})

			if (requiredDatabaseChanges.length && rowAlreadyEditted) {
				return
			}

			const oldRecord = _cloneDeep(record)
			const updatedRow = _cloneDeep(rowUpdates[record._id]?.newRecord ?? record)

			databaseChanges.forEach((_change) => {
				const customValue = customFields?.get(_change.field)
				const changedColumn = mappedColumns.get(_change.field)

				if (changedColumn?.type === TableTypes.FieldType.CALCULATION) {
					updatedRow.overrides = {
						...updatedRow.overrides,
						[_change.field]: _change.value ?? customValue,
					}
					return
				}

				const existingChange = updatedRow.data[_change.field]
				if (
					existingChange === undefined ||
					(existingChange !== customValue && existingChange !== _change.value)
				) {
					updatedRow.data[_change.field] = customValue ?? _change.value
				}
			})

			rowUpdates[record._id] = { newRecord: updatedRow, oldRecord }
			return
		})
	})

	return {
		newRows: _newRows,
		rowUpdates: Object.values(rowUpdates),
		rowsToReset,
	}
}

// Combinations a task can be completed
// - No options
// - Single option
// - Multiple options
// - Instance input

// Combinations of expected outcomes from completing a task
// - creates the next task
// - creates only the next task tas per the selection criteria
// - creates a task for every option selected
// - creates a multi use task with instance name
// - creates a task for every chain defined in the database table (cannot be combined with "create a task for every option selected")

const mergeActionsAndAttachments = ({
	chainActions,
	projectChain,
	projectTasks,
	externalReferences,
	groupId,
}: {
	chainActions: Array<{
		actions?: V3ServerTypes.Blueprint.StepAction[]
		attachments?: V3ServerTypes.Blueprint.StepAction[]
		option_id: string
	}>
	projectChain?: V3ProjectTypes.ProjectChain
	projectTasks: V3ClientTypes.Project.Task[]
	externalReferences: ChainTypes.ProjectExternalChainReference[]
	groupId: string
}) => {
	return chainActions.reduce(
		(
			acc: {
				actions: V3ServerTypes.Blueprint.StepAction[]
				attachments: V3ServerTypes.Blueprint.StepAction[]
			},
			{ actions = [], attachments = [], option_id },
		) => {
			if (
				projectChain?.option_id &&
				projectChain.option_id !== option_id &&
				option_id !== V3BlueprintTypes.Task_Action_All
			) {
				return acc
			}

			const parentTasks = projectTasks.filter((_projectTask) => {
				if (!_projectTask.chain || !projectChain) {
					return true
				}
				const _parentTaskChains = [..._projectTask.chain.parent_chains, _projectTask.chain]

				return _parentTaskChains.every((_parentChain) => {
					if (
						!projectChain.parent_chains.every(
							({ original_descendant_id }) =>
								original_descendant_id !== _parentChain.original_descendant_id,
						)
					) {
						return true
					}
					return projectChain.parent_chains.some((_projectChain) => {
						return (
							_projectChain.original_descendant_id === _parentChain.original_descendant_id &&
							_projectChain._id === _parentChain._id
						)
					})
				})
			})

			if (
				option_id !== V3BlueprintTypes.Task_Action_All &&
				!parentTasks.some((parentTask) =>
					parentTask.lifecycle.completed_options.some(
						(completedOption) => completedOption._id === option_id,
					),
				)
			) {
				return acc
			}

			const processItems = <T extends V3ServerTypes.Blueprint.StepAction>(
				items: T[],
				target: T[],
			) => {
				items.forEach((item) => {
					const previousTaskReferenceIndex = target.findIndex(
						(prevItem) => prevItem.type === V3BlueprintTypes.ActionEnum.TaskReference,
					)
					const fixedTaskReference = item.task_references.map(({ task, fields }) => {
						const foundExternalReferenceStep = externalReferences?.find(
							({ _id, group }) => _id === task && groupId === group,
						)
						if (foundExternalReferenceStep) {
							return {
								fields,
								task: foundExternalReferenceStep.step,
							}
						}
						return {
							task,
							fields,
						}
					})

					if (
						item.type === V3BlueprintTypes.ActionEnum.TaskReference &&
						previousTaskReferenceIndex > -1
					) {
						target[previousTaskReferenceIndex].task_references = [
							...(target[previousTaskReferenceIndex].task_references || []),
							...(fixedTaskReference || []),
						]
					} else {
						target.push({
							...item,
							task_references: fixedTaskReference || [],
						})
					}
				})
			}

			processItems(actions, acc.actions)
			processItems(attachments, acc.attachments)

			return acc
		},
		{ actions: [], attachments: [] },
	)
}

const getMultiActionFileIds = ({
	stepAction,
	mappedFiles,
	filesToBeCopied: filesToBeCopiedOriginal,
	taskId,
	actionIndex,
	type,
}: {
	stepAction: V3ServerTypes.Blueprint.StepAction
	mappedFiles: Map<string, string>
	filesToBeCopied: V3ProjectSdk.FilesToBeCopiedPayload[]
	taskId: string
	actionIndex: number | undefined
	type: 'action' | 'attachment'
}) => {
	if (!stepAction.file_ids?.length)
		return {
			fileIds: [],
			filesToBeCopied: filesToBeCopiedOriginal,
		}
	const filesToBeCopied = [...filesToBeCopiedOriginal]

	const newFileIds = stepAction.file_ids.reduce((fileIds: string[], fileId, index) => {
		const existingUpload = mappedFiles.get(fileId)
		if (existingUpload && stepAction.type !== V3BlueprintTypes.ActionEnum.FileTemplate) {
			return [...fileIds, existingUpload]
		}

		filesToBeCopied.push({
			originalFileId: fileId,
			tasksUsingFile: [{ _id: taskId, fileIndex: index, actionIndex, type }],
		})

		return [...fileIds, V3ProjectSdk.UPLOADING_TEXT]
	}, [])

	return {
		fileIds: newFileIds,
		filesToBeCopied,
	}
}

export const getTaskActionsAndAttachments = ({
	newStep,
	projectChain,
	projectTasks,
	mappedFiles,
	filesToBeCopied,
	taskId,
	externalReferences,
	groupId,
}: {
	projectChain?: V3ProjectTypes.ProjectChain
	newStep: V3ClientTypes.Blueprint.Step
	projectTasks: V3ClientTypes.Project.Task[]
	mappedFiles: Map<string, string>
	filesToBeCopied: V3ProjectSdk.FilesToBeCopiedPayload[]
	taskId: string
	externalReferences: ChainTypes.ProjectExternalChainReference[]
	groupId: string
}): {
	actions: V3ServerTypes.Project.Task['actions']
	attachments: V3ServerTypes.Project.Task['attachments']
	newFilesToBeCopied: V3ProjectSdk.FilesToBeCopiedPayload[]
} => {
	const { actions: mergedTaskActions, attachments: mergedAttachments } = mergeActionsAndAttachments(
		{
			chainActions: newStep.chain_actions,
			projectChain,
			projectTasks,
			externalReferences,
			groupId,
		},
	)

	let newFilesToBeCopied: V3ProjectSdk.FilesToBeCopiedPayload[] = []

	const taskActions = mergedTaskActions.map((_action, index) => {
		if (_action.file_ids?.length) {
			const { fileIds, filesToBeCopied: _filesToBeCopied } = getMultiActionFileIds({
				stepAction: _action,
				mappedFiles,
				filesToBeCopied,
				taskId,
				actionIndex: index,
				type: 'action',
			})

			newFilesToBeCopied = _uniqBy(_filesToBeCopied, 'newFileId')
			return {
				..._action,
				file_ids: fileIds,
			}
		}
		return _action
	})

	const taskAttachments = mergedAttachments.map((_action, index) => {
		if (_action.file_ids?.length) {
			const { fileIds, filesToBeCopied: _filesToBeCopied } = getMultiActionFileIds({
				stepAction: _action,
				mappedFiles,
				filesToBeCopied,
				taskId,
				actionIndex: index,
				type: 'attachment',
			})

			newFilesToBeCopied = _uniqBy(_filesToBeCopied, 'newFileId')
			return {
				..._action,
				file_ids: fileIds,
			}
		}
		return _action
	})

	return {
		actions: taskActions,
		attachments: taskAttachments,
		newFilesToBeCopied,
	}
}

export const getTaskIteration = ({
	stepId,
	projectTasks,
	taskParent,
	projectChain,
}: {
	stepId: string
	projectTasks: V3ClientTypes.Project.Task[]
	taskParent?: string
	projectChain?: V3ProjectTypes.ProjectChain
}) => {
	if (!projectTasks.some((_task) => _task.step?._id === stepId)) {
		return 1
	}

	const parentStepId = taskParent
		? projectTasks.find((_task) => _task._id === taskParent)?.step
		: undefined

	const tasksInChain = projectTasks.filter((_task) => {
		if (!_task.step || _task.isMultiUse || _task.step._id !== stepId) return false
		const _taskParent = projectTasks.find(
			(_projectTask) => !!_task.parent && _projectTask._id === _task.parent._id,
		)
		if (!_taskParent?.step || _taskParent.step !== parentStepId) {
			return false
		}
		if (projectChain?._id) {
			return _task.chain?._id === projectChain._id
		}
		return true
	})

	if (!tasksInChain.length) return 1
	const iterations = tasksInChain.map((_task) => _task.iteration)
	const maxIteration = Math.max(...iterations)
	return maxIteration + 1
}

export const buildFilterModel = ({
	descendant,
	options,
}: {
	descendant: Pick<V3BlueprintTypes.Descendant, 'database_chain_logic'>
	options?: {
		columnId?: string
		labels: string[]
	}
}): GridFilterModel => {
	const filter: GridFilterModel = {
		items: [],
	}

	if (descendant?.database_chain_logic?.filters.items) {
		filter.items.push(...descendant.database_chain_logic.filters.items)
	}

	if (!options) {
		return filter
	}

	if (descendant.database_chain_logic?.column) {
		if (options.labels.length > 1) {
			filter.items.push({
				id: v4(),
				field: descendant.database_chain_logic.column,
				operator: 'isAnyOf',
				value: options.labels,
			})
		} else {
			filter.items.push({
				id: v4(),
				field: descendant.database_chain_logic.column,
				operator: 'is',
				value: options.labels[0],
			})
		}
	} else if (options.columnId) {
		if (options.labels.length > 1) {
			filter.items.push({
				id: v4(),
				field: options.columnId,
				operator: 'isAnyOf',
				value: options.labels,
			})
		} else {
			filter.items.push({
				id: v4(),
				field: options.columnId,
				operator: 'is',
				value: options.labels[0],
			})
		}
	}

	return filter
}

export const getNewChainAfterEnding = ({
	projectChain,
	parentChains,
	descendant,
}: {
	projectChain: V3ProjectTypes.ProjectChain
	parentChains: V3ProjectTypes.ProjectChain[]
	descendant: V3ClientTypes.Project.ProjectDescendant
}): V3ProjectTypes.ProjectChain | undefined => {
	const isEndOfChain = descendant.chain_endings?.includes(projectChain.original_descendant_id)
	if (isEndOfChain) {
		const newParentChains = parentChains.filter(
			(_pChain) => !descendant.chain_endings?.includes(_pChain.original_descendant_id),
		)
		const newChain = newParentChains.pop()
		if (!newChain) {
			return undefined
		}

		return {
			...newChain,
			parent_chains: newParentChains,
		}
	}
	return projectChain
}

export const buildParentChains = ({
	parentTask,
	parentChains,
	descendant,
}: {
	parentChains: V3ProjectTypes.ProjectChain[]
	parentTask?: CompletingTask
	descendant: V3ClientTypes.Project.ProjectDescendant
}) => {
	const parentTaskChain = parentTask?.chain
		? _omit(parentTask.chain, 'parent_chain_tasks')
		: undefined
	const chains = _uniqBy([...parentChains, ...(parentTaskChain ? [parentTaskChain] : [])], '_id')
	return chains.filter(
		(_chain) => !descendant.chain_endings?.includes(_chain.original_descendant_id),
	)
}

export const populateDescendants = (
	descendants: V3BlueprintTypes.Descendant[],
	mappedSteps: Map<string, V3ServerTypes.Blueprint.Step>,
) =>
	descendants.reduce(
		(
			_descStepDescendants: V3ClientTypes.Project.ProjectDescendantOfDescendant[],
			_descStepDescendant,
		) => {
			if (!_descStepDescendant.step) {
				return _descStepDescendants
			}
			const descStepDescendantStep = mappedSteps.get(_descStepDescendant.step)
			if (!descStepDescendantStep) {
				return _descStepDescendants
			}
			_descStepDescendants.push({
				..._descStepDescendant,
				step: descStepDescendantStep,
			})

			return _descStepDescendants
		},
		[],
	)

export const convertAndPopulateDescendants = (
	descendants: V3ClientTypes.Blueprint.Step['descendants'],
	mappedSteps: Map<string, V3ClientTypes.Blueprint.Step>,
) =>
	descendants.reduce((_descendants: V3ClientTypes.Project.ProjectDescendant[], _desc) => {
		if (!_desc.step) {
			return _descendants
		}

		const descStep = mappedSteps.get(_desc.step)

		if (!descStep) {
			return _descendants
		}

		const descendants = populateDescendants(descStep.descendants, mappedSteps)
		_descendants.push({
			..._desc,
			step: { ...descStep, descendants },
		})
		return _descendants
	}, [])

type ReferenceTask = Pick<V3ServerTypes.Project.Task, '_id' | 'chain' | 'iteration' | 'step'>
export const findTaskRefs = (
	tasks: ReferenceTask[],
	taskIteration: number,
	taskToMatch: string,
): string[] => {
	const matchCondition = (_task: ReferenceTask) => {
		const isSameStep = _task.step === taskToMatch

		const isTaskOutsideChain = !_task.chain?._id
		if (isTaskOutsideChain) {
			return isSameStep
		}
		const isSameIteration = _task.iteration === taskIteration
		return taskIteration > 1 ? isSameStep && isSameIteration : isSameStep
	}

	const taskRefs = _filter(tasks, matchCondition)

	return taskRefs.map((taskRef) => taskRef._id)
}

const getReferenceIdsForTask = ({
	actions,
	tasks,
	taskIteration,
}: {
	actions: V3ServerTypes.Project.Task['actions']
	tasks: ReferenceTask[]
	taskIteration: number
}) => {
	return actions.reduce((_acc: V3ServerTypes.Blueprint.StepAction[], taskAction) => {
		return [
			..._acc,
			{
				...taskAction,
				task_references:
					taskAction.task_references?.reduce(
						(acc: V3ServerTypes.Blueprint.StepAction['task_references'], reference) => {
							const taskRefs = findTaskRefs(tasks, taskIteration, reference.task)
							return [...acc, ...taskRefs.map((_ref) => ({ task: _ref, fields: reference.fields }))]
						},
						[],
					) ?? [],
			},
		]
	}, [])
}

export const getTaskReferences = ({
	task,
	mockedTasks,
	chainTasks,
}: {
	task: V3ServerTypes.Project.Task
	mockedTasks: V3ServerTypes.Project.Task[]
	chainTasks: V3ClientTypes.Project.Task[]
}): V3ServerTypes.Project.Task => {
	const chainTasksAsReferenceTasks = chainTasks.map(
		(task): ReferenceTask => ({
			step: task.step?._id,
			_id: task._id,
			chain: task.chain,
			iteration: task.iteration,
		}),
	)

	const allTasks: ReferenceTask[] = [...chainTasksAsReferenceTasks, ...mockedTasks]
	const actions = getReferenceIdsForTask({
		actions: task.actions,
		tasks: allTasks,
		taskIteration: task.iteration,
	})
	const attachments = getReferenceIdsForTask({
		actions: task.attachments,
		tasks: allTasks,
		taskIteration: task.iteration,
	})
	return {
		...task,
		actions,
		attachments,
	}
}

export const createInstanceChain = (
	instanceName: string,
	parentChains: V3ProjectTypes.ProjectChain[],
): V3ProjectTypes.ProjectChain => ({
	_id: v4(),
	parent_chains: parentChains,
	original_descendant_id: '',
	label: {
		prefix: instanceName,
	},
})
