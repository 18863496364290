import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import _set from 'lodash/set'
import { Path } from 'react-hook-form'
import { V3ProjectSdk } from '@cango-app/sdk'
import { ChainTypes, V3ClientTypes, V3ServerTypes } from '@cango-app/types'

import { ProjectFilesState, ProjectsLoadingState, ProjectsStateV3, TaskListType } from './types'
import * as thunks from './thunks'

const initialState: ProjectsStateV3 = {
	allProjects: {},
	projectCards: [],
	projectFiles: [],
	projectFilesState: ProjectFilesState.Idle,
	tasks: [],
	project: undefined,
	isChatModalOpen: false,
	filesToUpload: 0,
	blockOptions: [],
	isLoadingBlockOptions: false,
	loadingProjectState: ProjectsLoadingState.Idle,
	loadingProjectsState: ProjectsLoadingState.Idle,
	tasksState: ProjectsLoadingState.Idle,
	myTaskListType: TaskListType.Active,
	project_wide_chains: [],
	database_ctas: [],
	project_steps: [],
	chain_starters: [],
}

export const projectsSliceV3 = createSlice({
	name: 'projects-v3',
	initialState,
	reducers: {
		endSession: () => initialState,
		resetFiles: (state: ProjectsStateV3) => {
			state.projectFiles = []
			state.projectFilesState = ProjectFilesState.Idle
		},
		setTaskListType: (state, action: PayloadAction<TaskListType>) => {
			state.myTaskListType = action.payload
		},
		updateTaskFileIds: (
			state,
			action: PayloadAction<V3ProjectSdk.GetTasksPendingUploadedFilesResponse>,
		) => {
			action.payload.forEach((_task) => {
				const taskIndex = state.tasks.findIndex(({ _id }) => _id === _task._id)
				if (taskIndex < 0) return
				state.tasks[taskIndex].actions = _task.actions
			})
		},
		setFilesTotalToUpload: (state, action: PayloadAction<number>) => {
			state.filesToUpload = action.payload
		},
		toggleChatModal: (state, action: PayloadAction<boolean>) => {
			state.isChatModalOpen = action.payload
		},
		resetTask: (
			state: ProjectsStateV3,
			action: PayloadAction<
				V3ProjectSdk.ResetTaskResponse & { updates: V3ProjectSdk.ResetTaskRequest }
			>,
		) => {
			const { updates } = action.payload
			const { task, newTasks } = action.payload
			const taskId = task._id
			state.tasks = [
				...state.tasks.reduce((acc: V3ClientTypes.Project.Task[], _task) => {
					if (updates.tasksToDelete.includes(_task._id)) {
						return acc
					}
					if (_task._id === taskId) {
						acc.push(task)
						return acc
					}
					acc.push(_task)
					return acc
				}, []),
				...newTasks,
			]
		},
		updateStoreTask: (
			state: ProjectsStateV3,
			action: PayloadAction<{
				taskId: string
				key: Path<V3ClientTypes.Project.Task>
				value: any
			}>,
		) => {
			const { taskId, key, value } = action.payload
			const taskIndex = state.tasks.findIndex(({ _id }) => _id === taskId)
			if (taskIndex < 0) return
			_set(state.tasks[taskIndex], key, value)
		},
	},
	extraReducers: (builder) => {
		builder.addCase(thunks.fetchMyTasksProjects.pending, () => ({
			...initialState,
			loadingProjectsState: ProjectsLoadingState.Loading,
		}))
		builder.addCase(thunks.fetchMyTasksProjects.fulfilled, (state, action) => {
			state.loadingProjectsState = ProjectsLoadingState.Fetched
			state.allProjects = action.payload.projects
		})
		builder.addCase(thunks.fetchMyTasksProjects.rejected, (state) => {
			state.loadingProjectsState = ProjectsLoadingState.Error
		})
		builder.addCase(thunks.getCards.fulfilled, (state: ProjectsStateV3, action) => {
			state.projectCards = action.payload
		})
		builder.addCase(thunks.getProjectSteps.fulfilled, (state: ProjectsStateV3, action) => {
			state.project_steps = action.payload.steps.reduce(
				(_acc: V3ServerTypes.Blueprint.Step[], _step) => {
					if (_acc.some(({ _id }) => _id === _step._id)) {
						return _acc
					}
					_acc.push(_step)
					return _acc
				},
				state.project_steps,
			)
			state.chain_starters = action.payload.chainStarters.reduce(
				(_acc: Pick<ChainTypes.BlueprintChain, '_id' | 'begins_with'>[], _step) => {
					if (_acc.some(({ _id }) => _id === _step._id)) {
						return _acc
					}
					_acc.push(_step)
					return _acc
				},
				state.chain_starters,
			)
		})
		builder.addCase(thunks.getProject.pending, (state: ProjectsStateV3) => {
			state.loadingProjectState = ProjectsLoadingState.Loading
			state.project = undefined
			state.tasks = []
			state.project_steps = []
		})
		builder.addCase(thunks.getProject.fulfilled, (state: ProjectsStateV3, action) => {
			state.project = action.payload.project
			state.project_wide_chains = action.payload.project_wide_chains
			state.database_ctas = action.payload.database_ctas
			state.allProjects[action.payload.project._id] = {
				...action.payload.project,
				task_count: state.allProjects[action.payload.project._id]?.task_count ?? 0,
			}
		})
		builder.addCase(thunks.getProject.rejected, (state: ProjectsStateV3) => {
			state.loadingProjectState = ProjectsLoadingState.Error
		})
		builder.addCase(thunks.getProjectTasks.pending, (state: ProjectsStateV3) => {
			state.tasksState = ProjectsLoadingState.Loading
		})
		builder.addCase(thunks.getProjectTasks.fulfilled, (state: ProjectsStateV3, action) => {
			const { projectId } = action.meta.arg
			state.tasks = action.payload.tasks
			state.loadingProjectState = ProjectsLoadingState.Fetched
			state.tasksState = ProjectsLoadingState.Fetched
			if (!state.allProjects[projectId]) return
			state.allProjects[projectId] = {
				...state.allProjects[projectId],
				task_count: action.payload.taskCount,
			}
		})
		builder.addCase(thunks.getProjectTasks.rejected, (state: ProjectsStateV3) => {
			state.tasksState = ProjectsLoadingState.Error
			state.loadingProjectsState = ProjectsLoadingState.Error
		})
		builder.addCase(thunks.archive.fulfilled, (state: ProjectsStateV3, action) => {
			const { archivedAt } = action.payload
			if (!state.project) return
			state.project.archived.at = archivedAt
		})
		builder.addCase(thunks.completeTask.fulfilled, (state: ProjectsStateV3, action) => {
			const { tasks, projectId, userId } = action.payload
			state.tasks = tasks.reduce(
				(_acc: V3ClientTypes.Project.Task[], _task) => {
					const existingTask = _acc.findIndex((_t) => _t._id === _task._id)
					if (existingTask > -1) {
						_acc[existingTask] = _task
					} else {
						_acc.push(_task)
					}
					return _acc
				},
				[...state.tasks],
			)
			if (!state.allProjects[projectId]) return
			state.allProjects[projectId] = {
				...state.allProjects[projectId],
				task_count: state.tasks.filter(
					({ assignees, lifecycle }) =>
						!lifecycle.complete && assignees.some(({ user }) => user === userId),
				).length,
			}
		})
		builder.addCase(thunks.restoreFromArchive.fulfilled, (state: ProjectsStateV3) => {
			if (!state.project) return
			state.project.archived = {
				state: false,
			}
		})
		builder
			.addCase(thunks.fetchProjectFiles.pending, (state) => {
				state.projectFilesState = ProjectFilesState.Loading
			})
			.addCase(thunks.fetchProjectFiles.fulfilled, (state, action) => {
				state.projectFiles = action.payload
				state.projectFilesState = ProjectFilesState.Idle
			})
			.addCase(thunks.fetchProjectFiles.rejected, (state) => {
				state.projectFilesState = ProjectFilesState.Error
			})
		builder.addCase(thunks.updateTask.fulfilled, (state, action) => {
			const newTask = action.payload
			const taskIndex = state.tasks.findIndex(({ _id }) => _id === newTask._id)
			if (taskIndex < 0) return
			state.tasks[taskIndex] = newTask
		})
		builder.addCase(thunks.assignUserToRole.fulfilled, (state, action) => {
			const {
				project: { tasks, ...project },
			} = action.payload
			state.project = project
			state.tasks = state.tasks.map((_task) => {
				const task = tasks.find(({ _id }) => _id === _task._id)
				return task ?? _task
			})
		})
		builder.addCase(thunks.updateProject.fulfilled, (state, action) => {
			state.project = { ...state.project, ...action.payload.response }
		})
		builder.addCase(thunks.assignContactToExternal.fulfilled, (state, action) => {
			if (!state.project) {
				return
			}
			state.project.externals = [...action.payload]
		})
		builder.addCase(thunks.createNewTask.fulfilled, (state, action) => {
			const { task } = action.payload
			state.tasks = [...(state.tasks ?? []), task]
		})
		builder.addCase(thunks.updateDependencies.fulfilled, (state, action) => {
			const tasks = action.payload
			tasks.forEach((task) => {
				const taskIndex = state.tasks.findIndex(({ _id }) => _id === task._id)
				if (taskIndex < 0) return
				state.tasks[taskIndex] = task
			})
		})
		builder.addCase(thunks.flagTask.fulfilled, (state, action) => {
			const { taskId } = action.meta.arg
			const blockedTaskIndex = state.tasks.findIndex(({ _id }) => _id === taskId)
			if (blockedTaskIndex === -1) {
				return
			}

			state.tasks[blockedTaskIndex].isFlagged = true

			state.tasks = [...state.tasks, ...action.payload]
		})
		builder.addCase(thunks.resolveFlaggedTask.fulfilled, (state, action) => {
			const { taskId } = action.meta.arg
			const blockedTaskIndex = state.tasks.findIndex(({ _id }) => _id === taskId)
			if (blockedTaskIndex === -1) {
				return
			}
			state.tasks[blockedTaskIndex].isFlagged = false
		})
		builder.addCase(thunks.fetchBlockOptions.pending, (state) => {
			state.isLoadingBlockOptions = true
		})
		builder.addCase(thunks.fetchBlockOptions.fulfilled, (state, action) => {
			state.isLoadingBlockOptions = false
			state.blockOptions = action.payload
		})
		builder.addCase(thunks.fetchBlockOptions.rejected, (state) => {
			state.isLoadingBlockOptions = false
		})
		builder.addCase(thunks.createOnDemandChain.fulfilled, (state, action) => {
			state.tasks = [...state.tasks, ...action.payload]
		})
		builder.addCase(thunks.createProject.fulfilled, (state) => {
			state.project = undefined
		})
	},
})
