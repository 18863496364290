/* eslint-disable @typescript-eslint/no-empty-function */

import { createContext } from 'react'

import { TaskDbChain } from 'src/modules/my-tasks-v3/components/complete-task-cta/types'

import { TaskProviderChildProps } from './types'

export const TaskContext = createContext<TaskProviderChildProps>({
	task: undefined,
	isLoadingTask: false,
	updateTask: async () => undefined,
	setNextTaskDbChains: () => [],
	nextTaskDbChains: {} as Record<string, TaskDbChain[]>,
	onClose: () => {},
	setSelectedOptions: () => {},
	selectedOptions: [],
	descendants: [],
})
