import { V3BlueprintSdk, FilesSdk, V3ProjectSdk, MyTasksV3Sdk } from '@cango-app/sdk'
import { ChainTypes, V3ClientTypes } from '@cango-app/types'

import { ListedStep } from 'src/modules/chains/types'

export type ListedProject = MyTasksV3Sdk.Project
export type ActiveProject = V3ClientTypes.Project.Project

export enum ProjectFilesState {
	Loading = 'loading',
	Error = 'error',
	Idle = 'idle',
}

export enum ProjectsLoadingState {
	Loading = 'loading',
	Error = 'error',
	Idle = 'idle',
	Fetched = 'fetched',
}

export enum TaskListType {
	Active = 'active',
	ProjectTasks = 'project-tasks',
}

export interface ProjectsStateV3 {
	allProjects: {
		[_id: string]: ListedProject
	}
	project: ActiveProject | undefined
	project_wide_chains: { _id: string; label: string }[]
	database_ctas: ChainTypes.DatabaseCTAsChains[]
	tasks: V3ClientTypes.Project.Task[]
	projectCards: V3BlueprintSdk.Card[]
	projectFiles: FilesSdk.GetFilesByFolderIdResponse
	projectFilesState: ProjectFilesState
	flaggedTaskId?: string
	isChatModalOpen: boolean
	filesToUpload: number
	blockOptions: MyTasksV3Sdk.BlockOptionsResponse
	isLoadingBlockOptions: boolean
	loadingProjectState: ProjectsLoadingState
	loadingProjectsState: ProjectsLoadingState
	tasksState: ProjectsLoadingState
	myTaskListType: TaskListType
	project_steps: ListedStep[]
	chain_starters: Pick<ChainTypes.BlueprintChain, '_id' | 'begins_with'>[]
}

export enum SectionBlockedReason {
	None = 'none',
	TasksAssignedToOtherUser = 'tasks-assigned-to-other-user',
}

export type CompleteTaskResponse = Omit<V3ProjectSdk.CompleteTaskResponse, 'sectionCount'> & {
	projectId: string
	userId: string
	taskId: string
	nextTaskId: string | undefined
}
