import { TableTypes } from '@cango-app/types'
import { TablesSdk } from '@cango-app/sdk'
import { GridColType, GridRowId, GridValidRowModel } from '@mui/x-data-grid-premium'

import { LinkedTable, MenuTableWithLinkedTables } from 'src/modules/tables/types'

export type TableProviderProps = {
	tableId?: string
}

export type ColumnFilterList = { _id: string; type: GridColType }[]

export type MappedValueOptions = Record<string, Map<string, string>>

export type UpdateRecordsRequestParams = {
	rows: {
		newRecord: TableTypes.Record
		oldRecord: TableTypes.Record
	}[]
	newRows?: TableTypes.Record[]
	field?: Partial<TableTypes.Field> & {
		_id: string
	} // Only one field can be updated at a time
	newFields?: TableTypes.Field[]
	save?: boolean
	config?: Partial<TableState['config']>
	resetRecords?: string[]
}
export type UpdateRecordsRequest = (
	data: UpdateRecordsRequestParams,
) => Promise<GridValidRowModel[]>

export type TaskProviderChildProps = {
	tableConfig: Omit<TableTypes.CangoTable, 'records' | 'fields'> | null
	isLoadingTable: boolean
	saveChanges: () => void
	hardSave: () => Promise<void>
	isUpdatingTable: boolean
	updateRecords: UpdateRecordsRequest
	discardAllChanges: () => void
	onAddRow: (data: { rowOrder: string[]; parentId?: string; newRowId: string }) => Promise<void>
	onAddColumn: () => Promise<void>
	columnList: { _id: string; label: string }[]
	columnFilterList: ColumnFilterList
	mappedColumns: Map<string, TableTypes.Field>
	mappedRowData: Map<string, ResolvedRowData & { _id: string }>
	mappedRecords: Map<string, TableTypes.Record>
	onUpdateColumn: (
		fieldId: string,
		updates: Omit<TablesSdk.UpdateFieldRequest, 'tableId'>,
		otherFields?: Partial<Omit<TableState, 'fields' | 'records'>>,
	) => Promise<{ result: 'success' | 'error' }>
	onDeleteColumn: (fieldId: string) => Promise<void>
	onDeleteRecords: (selectedRows: Map<GridRowId, GridValidRowModel>) => Promise<void>
	updateTableConfig: (data: TablesSdk.UpdateTableConfigRequest, save?: boolean) => Promise<void>
	tableList: MenuTableWithLinkedTables[]
	isFetchingTableList: boolean
	updateTableListItem: (
		tableId: string,
		data: Partial<TablesSdk.MenuTable & { _action: 'delete' | 'new' }>,
	) => Promise<void>
	linkedTable: LinkedTable | undefined
	fetchTable: (tableId: string, forceFetch?: boolean) => Promise<void>
	resolvedRows: ResolvedRowData[]
	resetCachedRecord: (recordIds: string[]) => void
	hardUpdateRecords: (records: Omit<TableTypes.Record, 'references'>[]) => Promise<void>
	columns: TableTypes.Field[]
	cachedRowIds: Set<string>
	blueprintRecords: Map<string, TableTypes.Record>
	referenceTables: Record<string, TableTypes.CangoTable>
	saveNewReferenceTables: (tables: Record<string, TableTypes.CangoTable>) => void
	mappedValueOptions: MappedValueOptions
}

export type TableConfig = Omit<TableTypes.CangoTable, 'records' | 'fields'>

export interface TableState {
	isFetching: boolean
	isFetchingTableList: boolean
	numberOfFetchTableRetries: number
	hasError: boolean
	isUpdating: boolean
	config: TableConfig | null
	records: TableTypes.Record[]
	fields: TableTypes.Field[]
	recordsBeforeChange: Record<string, TableTypes.Record>
	newRecords: string[]
	configBeforeChange: Omit<TableTypes.CangoTable, 'records' | 'fields'> | null
	tableList: MenuTableWithLinkedTables[]
	blueprintTable: TableTypes.CangoTable | null
	referenceTables: Record<string, TableTypes.CangoTable>
}

export enum TableActionType {
	UPDATE_STATE = 'UPDATE_STATE',
	RESET = 'RESET',
	UPDATE_AND_REMOVE = 'UPDATE_AND_REMOVE',
}

export type TableAction =
	| {
			type: TableActionType.UPDATE_STATE
			payload: Partial<TableState>
	  }
	| {
			type: TableActionType.RESET
	  }
	| {
			type: TableActionType.UPDATE_AND_REMOVE
			payload: Pick<TableState, 'recordsBeforeChange' | 'newRecords'>
			update: Partial<Omit<TableState, 'recordsBeforeChange' | 'newRecords'>>
	  }

export type ResolvedRowData = { _id: string } & TableTypes.Record['data']

export type ResolveAnyRowCalculations = {
	fields: TableTypes.Field[]
	row: TableTypes.Record
	referenceColumns: TableTypes.ReferenceColumnNames
	questionaireAnswers: TableTypes.QuestionaireAnswer[]
	images: Map<string, string>
	vLookupTables: TableTypes.VLookupTables
}
