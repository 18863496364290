import React, { ComponentType, useMemo, useState } from 'react'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { v4 } from 'uuid'

import { palette } from 'src/theme/colors'

import { TextField, TextFieldProps } from './text-field'

const filter = createFilterOptions<Options>()

interface CreatableTextFieldProps {
	label?: string
	required?: boolean
	onChange: (newValue: string | { _id: string; label: string }) => void
	value: string
	options: Options[]
	textFieldProps?: TextFieldProps
}

interface Options {
	_id: string
	label: string
	inputLabel?: string
	group: string
}

export const CreatableTextField: ComponentType<CreatableTextFieldProps> = ({
	label,
	required,
	onChange,
	value,
	options,
	textFieldProps,
}) => {
	const [currentInput, setCurrentInput] = useState('')
	const handleAddOption = (option: Options | null) => {
		if (!option || !option._id) {
			onChange('')
			return
		}
		const foundOption = options.find(({ _id }) => option._id === _id)
		if (foundOption) {
			onChange(option._id)
			return
		}
		return option?.inputLabel
			? onChange({
					_id: option._id,
					label: option.inputLabel,
				})
			: onChange({
					_id: option._id,
					label: option.label,
				})
	}
	const valueLabel = useMemo(() => {
		const foundOption = options.find(({ _id }) => _id === value)
		if (foundOption) {
			return foundOption.label
		}
		return currentInput
	}, [value])
	return (
		<>
			<Autocomplete
				value={{ _id: value, label: valueLabel } as Options}
				onChange={(event, newValue) => {
					if (typeof newValue === 'string') {
						handleAddOption({
							_id: v4(),
							label: newValue,
							group: 'External',
						})
						setCurrentInput(newValue)
					} else {
						handleAddOption(newValue)
					}
				}}
				filterOptions={(options, params) => {
					const filtered = filter(options, params)

					const { inputValue } = params

					const isExisting = options.some((option) => inputValue === option._id)
					if (inputValue !== '' && !isExisting) {
						filtered.push({
							_id: v4(),
							label: `Add "${inputValue}"`,
							inputLabel: inputValue,
							group: 'External',
						})
					}

					return filtered
				}}
				selectOnFocus
				clearOnBlur
				handleHomeEndKeys
				id="free-solo"
				fullWidth
				options={options}
				groupBy={(option) => option.group}
				getOptionLabel={(option) => {
					if (typeof option === 'string') {
						return option
					}
					return option.label
				}}
				renderOption={(props, option) => <li {...props}>{option.label}</li>}
				freeSolo
				renderInput={(params) => {
					const { className, ...restInputProps } = params.InputProps
					return (
						<TextField
							{...params}
							{...textFieldProps}
							InputProps={restInputProps}
							label={label}
							required={required}
							fullWidth
							sx={{
								bgcolor: 'white',
								'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
									borderColor: palette.borders.main,
								},
								maxWidth: 500,
							}}
						/>
					)
				}}
			/>
		</>
	)
}
